import React, {useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link >
        Metropole Laboratories Private Limited
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height:'100%'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide() {
  const classes = useStyles();
  const [ID,setID]=React.useState('')
  const [password,setPassword]=React.useState('')
useEffect(()=>{
    if(localStorage.getItem('user_id')!=null && localStorage.getItem('user_id')!=undefined){
        window.location.href="https://booking.mpl-labs.pk"
      }
},[])
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7}  >
      <img src={require("../mpl.PNG")} className={classes.image} />  
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
        <img src={require('../images/logo.png')} width="30%"/>
          <Typography component="h1" variant="h5">
            Welcome Branch Managers
          </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="id"
              label="Username"
              name="ID"
              value={ID}
              onChange={(e)=>{
                setID(e.target.value)
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e)=>{
                setPassword(e.target.value)
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={()=>{
                if(ID.trim()==''){
                  alert("Invalid Username")
                  return
                }
                if(password.trim()==''){
                  alert("Invalid Password")
                }
                   var log='ID'
                  fetch('https://reports.mpl-labs.pk:8443/4DACTION/WebLogin',{
                    method: 'POST',
                    'Content-Type':"application/json",
                    body:'vUID=' + ID + '&vPW=' + password + '&vRole=Admin&vLoginType=' + log + "&vRandom=" + 21211
                  }).then((res)=>res.text()).then( async(resss)=>{
                    console.log(resss)
                    var res=JSON.parse(resss)
                    if(res.Result){
                      localStorage.clear()
                      localStorage.setItem('token',password+"")
                      localStorage.setItem('user_id',res.User_ID)
                      localStorage.setItem('name',res.Name)
                      localStorage.setItem('branch_id',res.Branch_ID);
                      localStorage.setItem('org_branch_id',res.Branch_ID);
                     
                      localStorage.setItem("panel_code",res.panel_code)
                      localStorage.setItem("panel_name",res.panel_name)
                      localStorage.setItem("ref_by_id",res.ref_by_id)
                      localStorage.setItem("ref_by_name",res.ref_by_name)
                      localStorage.setItem('branch_name',"")
                      localStorage.setItem("patient_mobile","")
                      localStorage.setItem("patient_name","")
                      
                      alert('Login Successfully!!')
                      setTimeout(()=>{
                        window.location.href="https://booking.mpl-labs.pk"
                      },1500)
                      
                    }
                    else{
                      alert('Invalid Credentials !')
                      return
                    }
                  }) 
              }}
            >
              Login
            </Button>
        
            <Box mt={5}>
              <Copyright />
            </Box>
          </div>
      </Grid>
    </Grid>
  );
}