import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  Stepper,
  Step, Box,
  StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import Modal from "@material-ui/core/Modal";

import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Paper from "@material-ui/core/Paper";

import DateFnsUtils from '@date-io/date-fns';


import Registration from './Registration/index'


import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';


import MenuItem from '@material-ui/core/MenuItem';


import Select from '@material-ui/core/Select';

import Autocomplete from "@material-ui/lab/Autocomplete";

import InputLabel from '@material-ui/core/InputLabel';



import FormControlLabel from "@material-ui/core/FormControlLabel";



import FormControl from "@material-ui/core/FormControl";

import Radio from '@material-ui/core/Radio';
import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { parse } from "date-fns";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Patient/Passenger Registration",
    "Panel , Branch & Flight Information",
    "Picture Attachment",
    "Pricing & Submission"
  ];
}
const BasicForm = () => {
  const { control } = useFormContext();
  return (
    <>
      <Controller
        control={control}
        name="firstName"
        render={({ field }) => (
          <TextField
            id="first-name"
            label="First Name"
            variant="outlined"
            placeholder="Enter Your First Name"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="lastName"
        render={({ field }) => (
          <TextField
            id="last-name"
            label="Last Name"
            variant="outlined"
            placeholder="Enter Your Last Name"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="nickName"
        render={({ field }) => (
          <TextField
            id="nick-name"
            label="Nick Name"
            variant="outlined"
            placeholder="Enter Your Nick Name"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
    </>
  );
};
const ContactForm = () => {
  const { control } = useFormContext();
  return (
    <>
      <Controller
        control={control}
        name="emailAddress"
        render={({ field }) => (
          <TextField
            id="email"
            label="E-mail"
            variant="outlined"
            placeholder="Enter Your E-mail Address"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="phoneNumber"
        render={({ field }) => (
          <TextField
            id="phone-number"
            label="Phone Number"
            variant="outlined"
            placeholder="Enter Your Phone Number"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="alternatePhone"
        render={({ field }) => (
          <TextField
            id="alternate-phone"
            label="Alternate Phone"
            variant="outlined"
            placeholder="Enter Your Alternate Phone"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
    </>
  );
};
const PersonalForm = () => {
  const { control } = useFormContext();
  return (
    <>
      <Controller
        control={control}
        name="address1"
        render={({ field }) => (
          <TextField
            id="address1"
            label="Address 1"
            variant="outlined"
            placeholder="Enter Your Address 1"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="address2"
        render={({ field }) => (
          <TextField
            id="address2"
            label="Address 2"
            variant="outlined"
            placeholder="Enter Your Address 2"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="country"
        render={({ field }) => (
          <TextField
            id="country"
            label="Country"
            variant="outlined"
            placeholder="Enter Your Country Name"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
    </>
  );
};
const PaymentForm = () => {
  const { control } = useFormContext();
  return (
    <>
      <Controller
        control={control}
        name="cardNumber"
        render={({ field }) => (
          <TextField
            id="cardNumber"
            label="Card Number"
            variant="outlined"
            placeholder="Enter Your Card Number"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="cardMonth"
        render={({ field }) => (
          <TextField
            id="cardMonth"
            label="Card Month"
            variant="outlined"
            placeholder="Enter Your Card Month"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="cardYear"
        render={({ field }) => (
          <TextField
            id="cardYear"
            label="Card Year"
            variant="outlined"
            placeholder="Enter Your Card Year"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
    </>
  );
};

function GetStepContent(step) {
  const classes = useStyles();
  const [classicModal, setClassicModal] = React.useState(false)
  const [search_table, setSearch_table] = React.useState([]);
  const [age, setAge] = React.useState("");
  const [lab_tests, setLab_test] = React.useState([]);
  const [branches, setBranches] = React.useState([])
  const [branch, setBranch] = React.useState("")
  const [covidRate,setCovidrate]=React.useState("")
  const [panelObj,setPanelObj]=React.useState({})

  const [state, setState] = React.useState({
    columns: [
      { title: "Code", field: "code" },
      { title: "Title", field: "title" },
      { title: "Description", field: "description" },
      { title: "Cost", field: "cost" }
    ],
    data: [{
      code: "Covid-19 RNA by PCR",
      title: "COVIDPCR",
      description: "Nasal Swab/Nasal Secretions",
      cost: "0"
    }]
  });


  const [patient_history, setPatientHistory] = React.useState([]);
  const [panel, setPanel] = React.useState("");
  const [total_bill, setTotal_Bill] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [selectOption, setSelectOption] = React.useState({});
  const [openHist, setOpenHist] = React.useState();
  const [ticket_no, setTicket_No] = React.useState('')
  const [flight_no, setFlight_No] = React.useState('')
  const [flight_date, setFlight_Date] = React.useState(new Date('01/01/1900'))
  const [flight_time, setFlight_time] = React.useState(0)
  const [airline, setAirline] = React.useState('')

  const [National, setNational] = React.useState("true")

  const [destination, setDestination] = React.useState('')
  const [amount, setAmount] = React.useState('')
  const [discount, setDiscount] = React.useState('')
  const [airport, setAirport] = React.useState('')
  const [payable, setPayable] = React.useState('')
  const [NormalCase, setNormalCase] = React.useState(true)
  const [country, setCountry] = React.useState('')
  const [CNIC, setCNIC] = React.useState('')
  const [saveInvoice, setSaveInvoice] = React.useState(false)
  const [passport, setPassport] = React.useState('')
  const [salesAgent, setSalesAgent] = React.useState('Select')
  const [salesAgentNameList, setSalesAgentNameList] = React.useState([])
  const [salesAgentList, setSalesAgentList] = React.useState([])
  const [selectBranch, setSelectBranch] = React.useState('')
  const [consentForm, setconsentForm] = React.useState('')
  const [panelList, setPanelList] = React.useState([])
  const [otherPanelCase, setOtherPanelCase] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState('Normal');

  const [receiving, setReceiving] = React.useState("");

  ///  Boolean of turkey

  const [turkishImageBoolean, setTurkishImageBoolean] = React.useState(false)
  const [turkishPassportBoolean,setTurkishPassportBoolean] = React.useState(false)
  const [PatientImage, setPatientImage] = React.useState("")

  const [BranchContactInfo,setBranchContactInfo]=React.useState({})
  // Passport

  const [PassportImage,setPassportImage]=React.useState("")

  // Sampling Picture
  const [SamplingPicture,setSamplingPicture]=React.useState("")

  // 28 Dec 2021
  const [name, setName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [id, setId] = React.useState("");
  const [date, setDate] = React.useState(new Date())
  const [CNIC_CallingUp,setCNIC_CallingUp]=React.useState("")
  const [Passport_CallingUp,setPassport_CallingUp]=React.useState("")
  

  // 04 Mar 2022
  const [airlineList,setAirlineList]=React.useState({})
  const [airlineType,setAirlineType]=React.useState("NON-TRAVELLER")
  const [airlineName,setairlineName]=React.useState("")

  // 07 Mar 2022
  const [airlineOherFlag,setAirlineOtherFlag]=React.useState(false)


  //////////////////////
  const handleFileRead = async (event) => {
    const file = event.target.files[0]
    var fileInput = document.getElementById('img');
    var filePath = fileInput.value;
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(filePath)) {
      alert('Please upload file having extensions .jpeg/.jpg/.png only.');
      fileInput.value = '';
      setconsentForm('')
      return false;
    } else {

      const base64 = await convertBase64(file)
      setconsentForm(base64)
    }

  }
  ///////////////////
  const handleFileReadImage = async (event) => {
    const file = event.target.files[0]
    var fileInput = document.getElementById('PatientImage');
    var filePath = fileInput.value;
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(filePath)) {
      alert('Please upload file having extensions .jpeg/.jpg/.png only.');
      fileInput.value = '';
      setPatientImage('')
      return false;
    } else {

      const base64 = await convertBase64(file)
      setPatientImage(base64)
    }

  }

  ///////////////////
  const handleFileReadPassportImage = async (event) => {
    const file = event.target.files[0]
    var fileInput = document.getElementById('PassportImage');
    var filePath = fileInput.value;
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(filePath)) {
      alert('Please upload file having extensions .jpeg/.jpg/.png only.');
      fileInput.value = '';
      setPassportImage('')
      return false;
    } else {

      const base64 = await convertBase64(file)
      setPassportImage(base64)
    }

  }
   /////////////////// Reading Sampling Picture
   const handleFileReadSamplingPicture = async (event) => {
    const file = event.target.files[0]
    var fileInput = document.getElementById('SamplingPicture');
    var filePath = fileInput.value;
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(filePath)) {
      alert('Please upload file having extensions .jpeg/.jpg/.png only.');
      fileInput.value = '';
      setSamplingPicture('')
      return false;
    } else {

      const base64 = await convertBase64(file)
      setSamplingPicture(base64)
    }

  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const handleChange = (event) => {
    setSaveInvoice(true)
    console.log(event.target.value)
    setSelectedValue(event.target.value);
    setNormalCase(false)
    
  };
  useEffect(() => {
    
    // setTimeout(() => {
    //   window.addEventListener("beforeunload", function (e) {
    //     localStorage.clear()
    //   });
    // }, 60000 * 20);

    var id = localStorage.getItem('branch_id')
    var name = localStorage.getItem('patient_name')==undefined || localStorage.getItem('patient_name')==null ? "" : localStorage.getItem('patient_name')
    
    setBranch(id)
    var mob_num = localStorage.getItem("patient_mobile")
    // Upon Page Refresh
    if(name!=null && name!=undefined && name!=""){
    setName(name)
    }

    setMobile(mob_num)
    var patient_cnic =(localStorage.getItem("patient_cnic")==null || localStorage.getItem("patient_cnic")==undefined) ? "" : localStorage.getItem("patient_cnic")
    setCNIC_CallingUp(patient_cnic)

    var obj = {
      Branch_ID: id,
      Panel_ID: (localStorage.getItem('panel_code') == undefined || localStorage.getItem('panel_code') == null || localStorage.getItem('panel_code') == "") ? "" : localStorage.getItem('panel_code'),
      Test_ID: "UR-001-T-0268"
    }
    if (localStorage.getItem('panel_code') != undefined && localStorage.getItem('panel_code') != null && localStorage.getItem('panel_code') != "") {
      setSelectedValue(localStorage.getItem("panel_code"))
      setNormalCase(false)
      setOtherPanelCase(true)
    }
    var rate=localStorage.getItem("branch_province")==undefined || localStorage.getItem("branch_province")==null  ? "3,500" : localStorage.getItem("branch_province").trim()=="" ? "3,500" : (localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="federal" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="kpk" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="ajk" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="gb" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="balochistan") ? "5,000" : localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="punjab" ? "3,000" : localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="sindh" ? "4,500" : "3,500" 
    setCovidrate(rate)
    var vSearchStr = JSON.stringify(obj)
    if (localStorage.getItem('name')!=undefined && !localStorage.getItem('name').toLowerCase().includes('mpl-')) {
      fetch("https://reports.mpl-labs.pk:8443/4DACTION/BookingSalesAgentsCallsUp").then((res) => res.json()).then((response) => {
        setSalesAgentList(response)
        var obj = {}
        response.map((item) => {
          obj[item.Agent_ID] = item.Agent_Name
        })
        setSalesAgentNameList(obj)

      })
      fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetPanelsListAll").then((res) => res.json()).then((response) => {
        var ArrPanels=[]
        var objPanel={}
        response.map((i)=>{
          if(i.Panel_Code=="AR-01069"){
            return
          }
          objPanel[i.Panel_Code]=i.Panel_Title
          ArrPanels.push({
            Panel_Code:i.Panel_Code,
            Panel_Title:i.Panel_Title
          })
        })
      // console.log(ArrPanels)
       setPanelObj(objPanel)
        setPanelList(ArrPanels)
      })

    }
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetPanelsListAll").then((res) => res.json()).then((response) => {
      var ArrPanels=[]
      var objPanel={}
      response.map((i)=>{
        if(i.Panel_Code=="AR-01069"){
          return
        }
        objPanel[i.Panel_Code]=i.Panel_Title
        ArrPanels.push({
          Panel_Code:i.Panel_Code,
          Panel_Title:i.Panel_Title
        })
      })
     
    // console.log(ArrPanels)
     setPanelObj(objPanel)
      setPanelList(ArrPanels)
    })

      fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
        setBranches(response)
        var obj = {}
        var objBranchInfo={}
        response.map((item) => {
          obj[item.BranchID] = item.BranchName
          objBranchInfo[item.BranchID]={
            ...item
          }
        })
     //   console.log(objBranchInfo)
     if(objBranchInfo!={}){
      var branchInfo=objBranchInfo[parseInt(localStorage.getItem('branch_id'))]
      console.log(branchInfo)
      if(branchInfo!=undefined){
        localStorage.setItem('branch_province',(branchInfo.Province+""))
        var rate=localStorage.getItem("branch_province")==undefined || localStorage.getItem("branch_province")==null  ? "3,500" : localStorage.getItem("branch_province").trim()=="" ? "3,500" : (localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="federal" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="kpk" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="ajk" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="gb" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="balochistan") ? "5,000" : localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="punjab" ? "3,000" : localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="sindh" ? "4,500" : "3,500"
        setCovidrate(rate)
      }
     
     }
    
        setBranchContactInfo(objBranchInfo)
        setSelectBranch(obj)
      })
  
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebAirlinesNamesListCallingUp").then(res => res.json()).then((response) => {
      setAirlineList(response)
     })
   
  }, [])

  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpenHist = () => {
    setOpenHist(true);
  };
  function calculateAge(birthday) {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  const getDataUrl = (img) => {
    var canvas = document.createElement('canvas')
    var ctx = canvas.getContext('2d')

    canvas.width = img.width
    canvas.height = img.height
    ctx.drawImage(img, 0, 0)

    // If the image is not png, the format
    // must be specified here
    return canvas.toDataURL()
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseHist = () => {
    setOpenHist(false);
  };

  const search_patient = () => {
    fetch(
      "https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetsPatientsList?vName=" +
      name +
      "&vMobile=" +
      mobile +
      "&vID=" +
      id +
      "&vCNIC=" +
      CNIC_CallingUp +
      "&vPassport=" +
      Passport_CallingUp
    )
      .then((res) => res.json())
      .then((response) => {
        var data = [];
        response.map((item) => {
          
          if (localStorage.getItem('panel_code') != undefined && localStorage.getItem('panel_code') != null && localStorage.getItem('panel_code') != "") {

            if (!item.Panel_Code.includes(selectedValue)) {
              return
            }
          }
         
          

          var obj = {
            Patient_ID: item.Patient_ID,
            Patient_Name: item.Patient_Name,
            MobileNo: item.MobileNo,
            ToAddress: item.ToAddress,
            eMail: item.eMail,
            cnic: item.CNIC,

            select: (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  var url = "https://reports.mpl-labs.pk:8443/4DACTION/WebCheckPictureAlreadyExist"
                  var vSearchStr={
                    patient_id: item.Patient_ID
                  }
                  fetch(url, {
                    method: "POST",
                    'Content-Type': "application/json",
                    body: JSON.stringify(vSearchStr)
                  }).then((res) => res.json()).then((response) => {
                    console.log(response)
                    setTurkishImageBoolean(response[0].If_PictureAlreadyExist)
                    setTurkishPassportBoolean(response[0].If_PassportImageAlreadyExist)
              
                  })

                  Populate(
                    item.Patient_ID,
                    item.Patient_Name,
                    item.MobileNo,
                    item.Panel_Name,
                    item.Patient_DOB,
                    item.CNIC,
                    item.Passport
                  );
                }}
              >
                Select
              </Button>
            ),
          };

          data.push(obj);
        });
        setSearch_table(data);
        handleOpen(true);


      });
  };
  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? "" : "0") + dd,
      (mm > 9 ? "" : "0") + mm,
      this.getFullYear(),
    ].join("/");
  };

  const Populate = (id, name, mobile, panel, dob, cnic, passport) => {
    setId(id);
    setName(name);
    var age = new Date(dob);
    setAge(calculateAge(age));
    setCNIC(cnic)
    setPassport(passport)
    setMobile(mobile);
    setPanel(panel);
    handleClose(false);
  };

  switch (step) {
    case 0:
      return <Registration />;

    case 1:
      return <>
        {
            localStorage.getItem('org_branch_id') == '0' ?
              <FormControl variant="filled" className={classes.formControl} style={{ width: "30em" }} >
                <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={branch}
                  onChange={(e) => {
                    var result = window.confirm('Are you Sure ?. Your want to change Branch?')
                    if (result) {
                      setBranch(e.target.value)

                      localStorage.setItem('branch_id', e.target.value)
                      localStorage.setItem('branch_name', selectBranch[e.target.value])
                      var branchInfo=BranchContactInfo[e.target.value]
                      localStorage.setItem('branch_province',(branchInfo.Province+""))
                      localStorage.setItem('branch_mobile',(branchInfo.MobileNo+""))
                      localStorage.setItem('branch_network',(branchInfo.MobileService+""))

                      window.location.reload()
                    }

                  }}
                >
                  <MenuItem value={"0"} disabled>Select</MenuItem>
                  {
                    branches.map((item) => {
                      return <MenuItem value={item.BranchID} key={item.BranchID}>{item.BranchName.split('MPL, ')[1]}</MenuItem>

                    })
                  }
                </Select>
              </FormControl>
              : null
          }
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={{
          margin:'auto',
          marginTop:'5em',
          width:'80%'
        }}>
          <MaterialTable
           icons={tableIcons}
            columns={[
              { field: "Patient_ID", title: "Patient ID" },
              { field: "ToAddress", title: "Title" },
              { field: "Patient_Name", title: "Patient Name" },
              { field: "MobileNo", title: "Mobile" },
              { field: "eMail", title: "Email" },
              { field: "cnic", title: "CNIC" },
              { field: "select", title: "Select" },
            ]}
            data={search_table}
            title={"Patient Record"}
          />
        </div>
      </Modal>
          <div style={{
          textAlign: 'center'
        }}><h3><b>Search Patients using Patient ID, Patient Name & Mobile Number</b></h3></div>
        <div style={{ display: "-webkit-inline-box", width: "100%" }}>
        &nbsp; 
            &nbsp; 
            &nbsp;  &nbsp; 
            &nbsp; 
            &nbsp; 
            &nbsp; 
            &nbsp; 
            <TextField
              id="outlined-required"
              label="ID"
              value={id}
              required={true}
              onChange={(e) => {
                setId(e.target.value);
              }}
              variant="outlined"
            />
            &nbsp; 
            &nbsp; 
            &nbsp; 
            <input type="submit" style={{
              position: 'absolute',
              left: '-9999px'
            }} />
            <TextField
              id="outlined-required"
              label="Name"
              required={true}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              variant="outlined"
            />
            &nbsp; 
            &nbsp; 
            &nbsp; 
            <TextField
              id="mobile"
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
              label="Mobile"
              variant="outlined"
            />
            &nbsp; 
            &nbsp; 
            &nbsp;
            <TextField
              id="outlined-required"
              value={CNIC_CallingUp}
              onChange={(e) => {
                setCNIC_CallingUp(e.target.value);
              }}
              label="CNIC"
              variant="outlined"
            />
            &nbsp; 
            &nbsp; 
            &nbsp; 
            <TextField
              id="outlined-required"
              value={Passport_CallingUp}
              onChange={(e) => {
                setPassport_CallingUp(e.target.value);
              }}
              label="Passport No."
              variant="outlined"
            />
            <br />
            <br />
            <br />
          
          
       

        </div>
        <div style={{textAlign:"center"}}>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "1em" , height:"3em"}}
            onClick={() => {
              if(name.toString().trim()!="" && ((id+"").trim()=="" &&  mobile.toString().trim()=="" && CNIC_CallingUp.toString().trim()=="" && Passport_CallingUp.toString().trim()=="")){
                alert("Only Name Can't be Search!!")
              return
              }
              if(mobile.toString().trim()!="" && ((id+"").trim()=="" && name.toString().trim()=="" && CNIC_CallingUp.toString().trim()=="" && Passport_CallingUp.toString().trim()=="")){
                alert("Only Mobile No. Can't be Search!!")
              return
              }
              if((id+"").trim()!="" || name.toString().trim()!="" || mobile.toString().trim()!="" || CNIC_CallingUp.toString().trim()!="" || Passport_CallingUp.toString().trim()!=""){
                search_patient();
              }else{
                alert("Please Search by ID, Name, Mobile No, CNIC, Passport No.")
              }
            }}
            className={classes.button}
          >
            Search
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "1em" , height:"3em"}}
            onClick={() => {
              setPanel("");
              setId("");
              setMobile("");
              setName("");
              setAge("");
              setCNIC("")
              setPassport("")
              setCNIC_CallingUp("")
              setPassport_CallingUp("")

              setState((prevState) => {
                var data = [];
                return { ...prevState, data };
              });
            }}
            className={classes.button}
          >
            Clear
          </Button>
 
           
           </div>
      {/* ///////////////////////////////////////////////////////////////////////////////////////////////// */}
      <div style={{
          marginLeft: 10,
          textAlign:"left"
        }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Nationality</FormLabel>
            <RadioGroup aria-label="Nationality" name="Nationality" value={National} onChange={(e) => {

              setNational(e.target.value)
            }}>
              <FormControlLabel value="true" control={<Radio />} label="Pakistani" />
              <FormControlLabel value="false" control={<Radio />} label="Other than Pakistani (Foreigner)" />
            </RadioGroup>
          </FormControl>
        </div>
          {/* ///////////////////////////////////////////////////////////////////////////////////////////////// */}

        
        {
          localStorage.getItem('org_branch_id') == '0'
            ?
            <div style={{
              textAlign: 'center'
            }}>

              <div style={{
                textAlign: 'center',

              }}>
                <h3>Sales Agent Selection</h3>
              </div>
              <div >
                <center>
                  <Autocomplete
                    id="country-select-demo"
                    style={{ width: "50em" }}
                    options={salesAgentList}
                    classes={{
                      option: classes.option
                    }}
                    autoHighlight
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        setSalesAgent(newValue.Agent_ID)
                      } else {
                        setSalesAgent("Select")
                      }
                    }}
                    getOptionLabel={(option) => option.Agent_Name}
                    renderOption={(option) => <React.Fragment>{option.Agent_Name}</React.Fragment>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a Sales Agent"
                        variant="outlined"
                        style={{
                          width: 500
                        }}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password" // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </center>
              </div>
             
              <div>

              </div>
              <div style={{
                textAlign: 'center',

              }}>
                <h3>Panel Selection</h3>
              </div>
              <div >
                <center>
                  <Autocomplete
                    id="country-select-demo"

                    options={panelList}
                    classes={{
                      option: classes.option
                    }}
                    autoHighlight
                    onChange={(event, newValue) => {
                      setconsentForm("")
                      if (newValue != null) {
                        handleChange({
                          target: {
                            value: newValue.Panel_Code
                          }
                        })
                      } else {
                        handleChange({
                          target: {
                            value: "Normal"
                          }
                        })
                      }
                    }}
                    getOptionLabel={(option) => option.Panel_Title}
                    style={{
                      width: 500
                    }}
                    renderOption={(option) => {
                      if(option.Panel_Code=="AR-01069"){
                      return
                    }else{
return <React.Fragment>{option.Panel_Title}</React.Fragment>
                    } }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a Panel"
                        variant="outlined"
                        style={{
                          width: 500
                        }}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password" // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </center>
                
              </div>
            </div>
            :
            (localStorage.getItem('panel_code') != undefined && localStorage.getItem('panel_code') != null && localStorage.getItem('panel_code') != "")
              ?
              <div
                style={
                  {
                    display: 'flex',
                    flexDirection: "row",
                    justifyContent: 'space-evenly'
                  }
                }
              >


                <TextField
                  required
                  id="outlined-required"
                  label="Panel"
                  value={localStorage.getItem('panel_name')}
                  style={{ width: "20em" }}
                  variant="outlined"
                />


                <TextField
                  id="outlined-required"
                  label="Ref By"
                  value={localStorage.getItem("ref_by_name")}

                  variant="outlined"
                />


              </div>
              :
              <div>
                

              </div>
        }
           {/* ///////////////////////////////////////////////////////////////////////////////////////////////// */}
         
          {/* ///////////////////////////////////////////////////////////////////////////////////////////////// */}
     <div style={{ display: "-webkit-inline-box", width: "100%", marginTop:20 }}>
          <TextField
            required
            id="outlined-required"
            label="Ticket No"
            disabled={airlineType=="NON-TRAVELLER"}
            value={ticket_no}
            onChange={(e) => {
              setTicket_No(e.target.value);
            }}
            variant="outlined"
          />
            &nbsp; 
            &nbsp; 
            &nbsp; 
          <TextField
            required
            id="outlined-required"
            label="Flight No"
            value={flight_no}

            disabled={airlineType=="NON-TRAVELLER"}
            onChange={(e) => {
              setFlight_No(e.target.value);
            }}
            variant="outlined"
          />
           &nbsp; 
            &nbsp; 
            &nbsp; 
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
             
              format="dd/MM/yyyy"
              margin="normal"
              disabled={airlineType=="NON-TRAVELLER"}
              label="Flight Date"
              value={flight_date}
              onChange={(e) => {
                
                setFlight_Date(e)
              }}
              KeyboardButtonProps={{
                'aria-label': 'Flight Date',
              }}
            />
            &nbsp; 
            &nbsp; 
            &nbsp; 
            <KeyboardTimePicker
              label="Flight Time"
              placeholder="08:00"
              mask="__:__"
              format="HH:mm"
              disabled={airlineType=="NON-TRAVELLER"}
              value={flight_time}
              onChange={date => {
                
                setFlight_time(date)
              }}
            />

          </MuiPickersUtilsProvider>
            &nbsp; 
            &nbsp; 
            &nbsp; 
            <FormControl variant="filled" className={classes.formControl} style={{ width: "15em" }} disabled={airlineType=="NON-TRAVELLER"} >
                <InputLabel id="demo-simple-select-filled-label">AirLine</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={airline}
                  required={airlineType!="NON-TRAVELLER"}
                  onChange={(e) => {
                    var str=e.target.value
                    setAirline(str)
                    if(airlineType=="OTHER" && str.split('|')[2]!="OTHER"){
                    setAirlineType("KSA")
                    }
                    setairlineName(str.split('|')[1])
                    setAirlineOtherFlag(str.split('|')[2]!="OTHER")
                  }}
                >
                   <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
          {
           Object.keys(airlineList).length==0
           ?
           null
           :
            Object.keys(JSON.parse(airlineList.ArrayAirline_ID)).map((airline_id,index)=>{

            return <MenuItem value={JSON.parse(airlineList.ArrayAirline_ID)[index]+"|"+JSON.parse(airlineList.ArrayAirline_Name)[index]+"|"+JSON.parse(airlineList.ArrayAirlineOtherTag)[index]} >{JSON.parse(airlineList.ArrayAirline_Name)[index]}</MenuItem>
               

            })
            
          }
           </Select>
          </FormControl>
          


        </div>

        <div style={{marginTop:20}}>
          <TextField
            required
            id="outlined-required"
            label="Airport"

            disabled={airlineType=="NON-TRAVELLER"}
            value={airport}
            onChange={(e) => {
              setAirport(e.target.value);
            }}
            variant="outlined"
          />
            &nbsp; 
            &nbsp; 
            &nbsp; 
          <TextField
            required
            id="outlined-required"
            label="Destination"
            disabled={airlineType=="NON-TRAVELLER"}
            value={destination}
            onChange={(e) => {
              setDestination(e.target.value);
            }}
            variant="outlined"
          />
            &nbsp; 
            &nbsp; 
            &nbsp; 
          <TextField
            required
            id="outlined-required"
            label="Country" 
            onChange={(e) => {
              setCountry(e.target.value);
            }}
            style={{ width: "30em" }}
            disabled={airlineType=="NON-TRAVELLER"}
            value={country}
            variant="outlined"
          />

        </div>
        <br />
        <br />
        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <div style={{
          marginLeft: 10,
          textAlign:"left"
        }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Category</FormLabel>
            <RadioGroup aria-label="Category" name="Category" value={airlineType} onChange={(e) => {

              setAirlineType(e.target.value)
              if(e.target.value=="NON-TRAVELLER"){
                handleChange({
                  target: {
                    value: "Normal"
                  }
                })
              }else{
                setOtherPanelCase(false)
              }
            
            }}>
              <FormControlLabel value="KSA" control={<Radio />} label="KSA" />
              <FormControlLabel value="GERRY" control={<Radio />} label="GERRY" />
              <FormControlLabel value="OTHER" disabled={airlineOherFlag} control={<Radio />} label="OTHER" />
              <FormControlLabel value="NON-TRAVELLER" control={<Radio />} label="NON-TRAVELLER" />
            </RadioGroup>
          </FormControl>
        </div>

      </>;
    case 2:
      return <>
      <div style={{
          display:"flex",
          flexDirection:'row'
        }}>
        {
          !turkishImageBoolean &&  (airlineType=="GERRY")
            ?
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: "center", width: "40%", margin: "auto" }}>
              <h3><b>Registration Photo</b></h3>
              <br />
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
              >
              {
                PatientImage==""
                ?
                <input type="file" variant="contained"
                color="primary"
                required
                onChange={(e) => { handleFileReadImage(e) }}
                id="PatientImage" name="PatientImage" accept="image/*"
                className={classes.button}

                aria-label="Attach PDF"
              />
              :
              <input type="file" variant="contained"
              color="primary"
              onChange={(e) => { handleFileReadImage(e) }}
              id="PatientImage" name="PatientImage" accept="image/*"
              className={classes.button}

              aria-label="Attach PDF"
            />
              }
                Registration Photo
              </Button>
              {
                PatientImage != "" ?
                  <img id="PatientImagePreview"  required={true} src={PatientImage} alt="Patient Form" width="250px" height="300px" style={{
                    margin: "auto"
                  }} />
                  : null
              }
            </div>
            : null
        }
         {
          !turkishPassportBoolean &&  (airlineType=="KSA" ||  airlineType=="OTHER" || airlineType=="GERRY")
            ?
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: "center", width: "40%", margin: "auto" }}>
             <h3> <b >Passport Image</b>  </h3>
              <br />
              <Button
                variant="contained"
                style={{
                  color:"white",
                  backgroundColor:"green"
                }}
                className={classes.button}
              >
                {
                PassportImage == "" ?
                <input type="file" variant="contained"
                 style={{
                  color:"white",
             
                  backgroundColor:"green"
                }}
                required
                 onChange={(e) => { handleFileReadPassportImage(e) }}
                  id="PassportImage" name="PassportImage" accept="image/*"
                  className={classes.button}

                  aria-label="Attach PDF"
                />
                :
                <input type="file" variant="contained"
                 style={{
                  color:"white",
             
                  backgroundColor:"green"
                }}
               
                 onChange={(e) => { handleFileReadPassportImage(e) }}
                  id="PassportImage" name="PassportImage" accept="image/*"
                  className={classes.button}

                  aria-label="Attach PDF"
                />
                
              }
                
                Passport
              </Button>
              {
                PassportImage != "" ?
                  <img id="PassportImagePreview" src={PassportImage} alt="Passport Form" width="250px" height="300px" style={{
                    margin: "auto"
                  }} />
                  : null
              }
            </div>
            : null
        }
          
         

        </div>
        <div style={{
          display:"flex",
          flexDirection:'row'
        }}>
        {
            airlineType=="KSA" ||  airlineType=="OTHER" ||  airlineType=="GERRY"
           ?
        <div style={{ display: 'flex', flexDirection: "column", textAlign: "center", width: "40%", margin: "auto"}}>
             <h3> <b >Sampling Picture</b>  </h3>
              <br />
              <Button
                variant="contained"
                color="secondary"
                
                className={classes.button}
              >
               {
                 SamplingPicture==""
                 ?
                 <input type="file" variant="contained"
                 color="secondary"
                 required={SamplingPicture=="" ? true :""}
                 onChange={(e) => { handleFileReadSamplingPicture(e) }}
                 id="SamplingPicture" name="SamplingPicture" accept="image/*"
                 className={classes.button}

                 aria-label="Attach PDF"
               />
               :
               <input type="file" variant="contained"
               color="secondary"
               onChange={(e) => { handleFileReadSamplingPicture(e) }}
               id="SamplingPicture" name="SamplingPicture" accept="image/*"
               className={classes.button}

               aria-label="Attach PDF"
             />
               }
              </Button>
              {
                SamplingPicture != "" ?
                  <img id="SamplingPicturePreview" src={SamplingPicture} alt="Sampling Picture"  width="300px" height="400px" style={{
                    margin: "auto"
                  }} />
                  : null
              }
            </div>
            :
            null
           }




        {
          airlineType=="GERRY"
            ?
            <div style={{ display: 'flex', flexDirection: "column", textAlign: "center", width: "40%", margin: "auto"}}>

              <b>Consent Form fo Gerry's - Covid19 PCR (Compulsory)</b>
              <br />

              <Button
                variant="contained"
                color="primary"
                className={classes.button}
              >
              {
                consentForm=="" ?
                <input type="file" variant="contained"
                color="primary"
                required={consentForm==""}
                onChange={(e) => { handleFileRead(e) }}
                id="img" name="img" accept="image/*"
                className={classes.button}

                aria-label="Attach PDF"
              />
              :
              <input type="file" variant="contained"
              color="primary"
              required={consentForm==""}
              onChange={(e) => { handleFileRead(e) }}
              id="img" name="img" accept="image/*"
              className={classes.button}

              aria-label="Attach PDF"
            />
              }
              </Button>
              {
                consentForm != "" ?
                  <img id="consentFormPreview" src={consentForm} alt="Consent Form" width="250px" height="300px" style={{
                    margin: "auto"
                  }} />
                  : null
              }
            </div>
            : null
        }
   </div>
      </>;

    case 3:
        return <div style={{
         border:"black solid 1px",
          padding:"2em",
          margin:"10px"
        }}>
            <Grid container >
            <Grid xs={1} sm={1} md={1} lg={1}></Grid>
             <Grid xs={2} sm={2} md={2} lg={2} >
               <div>ID</div>
               <h3>{id}</h3>
             </Grid>
             <Grid xs={2} sm={2} md={2} lg={2}>
             <div>Name</div>
               <h3>{name}</h3>
             </Grid>
             <Grid xs={2} sm={2} md={2} lg={2}>
             <div>CNIC</div>
               <h3>{CNIC}</h3>
             </Grid>
             <Grid xs={2} sm={2} md={2} lg={2}>
             <div>Passport</div>
               <h3>{passport}</h3>
             </Grid>
             <Grid xs={3} sm={3} md={3} lg={3}>
               <div>Panel</div>
               <h3>{selectedValue=="Normal"? "NON-PANEL" :panelObj[selectedValue]}</h3>
             </Grid>
             
             
            
            </Grid>
<br />
           {
         airlineType!="NON-TRAVELLER" &&  ticket_no!=""
             ?
             <>
             <Grid container >
             <Grid xs={1} sm={1} md={1} lg={1}></Grid>
              <Grid xs={2} sm={2} md={2} lg={2} >
                <div>Ticket No</div>
                <h3>{ticket_no}</h3>
              </Grid>
              <Grid xs={2} sm={2} md={2} lg={2}>
              <div>Flight No</div>
                <h3>{flight_no}</h3>
              </Grid>
              <Grid xs={2} sm={2} md={2} lg={2}>
              <div>Flight Date</div>
                <h3>{flight_date.ddmmyyy()}</h3>
              </Grid>
              <Grid xs={2} sm={2} md={2} lg={2}>
              <div>Flight Time</div>
                <h3>{flight_time==0 ? null : formatAMPM(flight_time)}</h3>
              </Grid>
              <Grid xs={2} sm={2} md={2} lg={2}>
                <div>AirLine</div>
                <h3>{airlineName}</h3>
              </Grid>
              
              
             
             </Grid>
              <Grid container >
              <Grid xs={1} sm={1} md={1} lg={1}></Grid>
               <Grid xs={2} sm={2} md={2} lg={2} >
                 <div>Airport</div>
                 <h3>{airport}</h3>
               </Grid>
               <Grid xs={2} sm={2} md={2} lg={2}>
               <div>Destination</div>
                 <h3>{destination}</h3>
               </Grid>
              </Grid>
              </>
             :
             null
           }
           
           <div style={{
          textAlign: "center"
        }}>
          
          <Grid container>
            <Grid xs={1} sm={1} md={1} lg={1}></Grid>
            <Grid xs={10} sm={10} md={10} lg={10}>
              {
               (total_bill != 0  && localStorage.getItem('price')=="true") ? 
                
                (localStorage.getItem('org_branch_id') == '0')
                  ?
                  <MaterialTable
                    columns={state.columns}
                    data={state.data}
                    options={{
                      paging: false,
                      maxBodyHeight: "15em",
                      minBodyHeight: "10em",
                      showTitle: false,
                      search: false,
                      headerStyle: { position: "sticky", top: 0, fontWeight:"bold" },
                    }}
                  />
                  :
                  null

                  : null}
            </Grid>
            <Grid xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>
        </div>
        <Grid container>
        <Grid xs={4} sm={4} md={4} lg={4}></Grid>
        <Grid xs={4} sm={4} md={4} lg={4} style={{
          textAlign:"center"
        }}>
        {
           localStorage.getItem('price')=="false"
          ?
          <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={()=>{
            var obj={
              airline_id:airline=="" ? "" : parseInt(JSON.parse(airline.split("|")[0])),
              airline_type:airlineType=="NON-TRAVELLER" ? "" : airlineType,
              Branch_ID: localStorage.getItem('branch_id'),
              Panel_ID: selectedValue=="Normal" ? "" : selectedValue ,
              Test_ID: "UR-001-T-0268"
            }
            console.log(obj)
            var vSearchStr = JSON.stringify(obj)
            var url = "https://reports.mpl-labs.pk:8443/4DACTION/BookingInvoiceCallsPriceTest"
      
            fetch(url, {
                    method: "POST",
                    'Content-Type': "application/json",
                    body: vSearchStr
                    }).then((res) => res.json()).then((response) => {
                      localStorage.setItem('price','true')
              setState({
                columns: [
                  { title: "Code", field: "code" },
                  { title: "Title", field: "title" },
                  { title: "Description", field: "description" },
                  { title: "Cost", field: "cost" }
                ],
                data: [{
                  code: response[0].Test_Code,
                  title: response[0].Test_Name,
                  description: "Nasal Swab/Nasal Secretions",
                  cost: response[0].Test_Price
                }]
              })
              setTotal_Bill(response[0].Test_Price)
              setAmount(response[0].Test_Price)
              setSaveInvoice(false)
              setReceiving("")
            })
          }}
        >Get Price</Button>
        :
        null
        }
        </Grid>
        <Grid xs={4} sm={4} md={4} lg={4}></Grid>
        </Grid>
        {
           localStorage.getItem('price')=="true" && total_bill != 0 
           ?
            (localStorage.getItem('org_branch_id') == '0' )
              ?
              
              <div style={{
                marginTop: 10
              }}>
                {
                  amount == parseInt(receiving)
                    ?
                    null : <h2 style={{
                      color: "red",
                      textAlign: 'center'
                    }}>Balance is Due ({receiving == "" ? amount : amount - parseInt(receiving)})</h2>
                }
                <center>

                  <TextField
                    required
                    id="outlined-required"
                    label="Amount"
                    value={amount}

                    variant="outlined"
                  />
                  <TextField
                    label="Payment Received"
                    value={receiving}
                    type="number"
                    onChange={(e) => {
                      var rec = e.target.value
                      var temp = rec.replace(/^0/, "")
                      if (parseInt(temp) > amount) {
                        setReceiving('')
                        return
                      }
                      setReceiving(temp)


                    }}
                    variant="outlined"
                  />
                </center>
              </div>
              
:
null
              : null}       
<br />
<br />
                    {/* ///////////////////////////////////////////////////////////////////////////////////////////////// */}
                    <Grid container>
        <Grid xs={4} sm={4} md={4} lg={4}></Grid>
        <Grid xs={4} sm={4} md={4} lg={4} style={{
          textAlign:"center"
        }}>
                    {
           localStorage.getItem('price')=="true"
          ?
          <Button
          variant="contained"
          color="primary"
          disabled={saveInvoice}
          className={classes.button}
          onClick={()=>{
                 setSaveInvoice(true)
                  var branch_id = localStorage.getItem('branch_id')


                  if (branch_id == "0") {
                    alert('Kindly Select Branch!!')

                    setSaveInvoice(false)
                    return
                  }
                  if (branch_id == undefined || branch_id == null) {
                    window.location.reload()
                    return
                  }

                  if (National == 'true') {
                    if (CNIC.trim() == '') {
                      alert('Failed, CNIC is compulsory for COVID-19')

                      setSaveInvoice(false)
                      return
                    }
                  }

                  if (total_bill == 0) {
                    alert("Kindly Contact MPL, For Configuration of Respective Panel Price.")
                    setSaveInvoice(false)
                    return
                  }

                  if (name.trim() == '') {
                    alert("Kindly Select Patient")

                    setSaveInvoice(false)
                    return
                  }
                  if (id.trim() == '') {
                    alert("Kindly Select Patient")

                    setSaveInvoice(false)
                    return
                  }
                  if (mobile.trim() == '') {
                    alert("Kindly Select Patient")

                    setSaveInvoice(false)
                    return
                  }


                  if (airlineType!="NON-TRAVELLER") {
                    if (National == 'true') {
                      if (CNIC.trim() == "" || passport.trim() == "") {
                        alert('Failed, CNIC & Passport is compulsory for Passengers')
                        setSaveInvoice(false)
                        return
                      }
                    } else {
                      if (passport.trim() == "") {
                        alert('Failed, Passport is compulsory for Passengers')
                        setSaveInvoice(false)
                        return
                      }
                    }

                    if (flight_time == 0) {
                      alert('Kindly Select Flight Time!!')

                      setSaveInvoice(false)
                      return

                    }
                    if (airline.trim() == '') {
                      alert('Invalid AirLine !')

                      setSaveInvoice(false)
                      return
                    }
                    if (ticket_no.trim() == '') {
                      alert('Invalid Ticket Number!')

                      setSaveInvoice(false)
                      return
                    }
                    if (flight_date.getFullYear() == '1900') {
                      alert('Invalid Flight Date!')

                      setSaveInvoice(false)
                      return

                    }
                    if (flight_no.trim() == '') {
                      alert('Invalid Flight Number!')

                      setSaveInvoice(false)
                      return
                    }
                    if (flight_date == 'Invalid Date') {
                      alert('Invalid Flight Date!')

                      setSaveInvoice(false)
                      return
                    }
                    if (flight_time == 'Invalid Date') {
                      alert('Invalid Flight Time!')

                      setSaveInvoice(false)
                      return
                    }
                    if (flight_time == '') {
                      alert('Invalid Flight Time!')

                      setSaveInvoice(false)
                      return
                    }
                    

                    if (airport.trim() == '') {
                      alert('Invalid Airport!')

                      setSaveInvoice(false)
                      return
                    }
                    if (destination.trim() == '') {
                      alert('Invalid Destination!')

                      setSaveInvoice(false)
                      return
                    }
                    flight_date.setHours(15)
                    var user_id = localStorage.getItem('user_id')
                    var branch_id = localStorage.getItem('branch_id')

                    var salesAgent_ID = ""
                    var salesAgent_Name = ""
                    if (salesAgent != "Select") {
                      salesAgent_ID = salesAgent
                      salesAgent_Name = salesAgentNameList[salesAgent_ID]
                    }
                    var receiving_cash = total_bill + ""
                    if (localStorage.getItem('org_branch_id') == '0' || localStorage.getItem('org_branch_id') == '5') {
                      if (receiving == '' || amount != parseInt(receiving)) {
                        var result = window.confirm('Are you Sure ?. Balance will be due !! ')
                        if (result) {
                          if (receiving == '') {
                            receiving_cash = "0"
                          } else {
                            receiving_cash = receiving
                          }
                        } else {
                          setSaveInvoice(false)
                          return
                        }

                      }
                    }

                    if (airlineType=="GERRY" && consentForm == "") {
                      alert("Kindly Upload Consent Form!!")
                      setSaveInvoice(false)
                      return
                    }
                    if ( PatientImage == "" && !turkishImageBoolean   &&  airlineType=="GERRY") {
                      alert("Kindly Upload Patient Image!!")
                      setSaveInvoice(false)
                      return
                    }
                    if (PassportImage == "" && !turkishPassportBoolean) {
                      alert("Kindly Upload Passport Image!!")
                      setSaveInvoice(false)
                      return
                    }
                    if (SamplingPicture == "") {
                      alert("Kindly Upload Sampling Picture !!")
                      setSaveInvoice(false)
                      return
                    }
                   
                    var SearchFormData = {
                      Branch_ID: branch_id,
                      User_ID: user_id,
                      ServiceID: "",
                      Ref_No: "",
                      Panel_ID: selectedValue=="Normal" ? "" : selectedValue ,
                      Patient_ID: id,
                      Ticket_No: ticket_no,
                      Flight_No: flight_no,
                      SampleTakingPicture:SamplingPicture.split('base64,')[1],
                      Flight_Date: flight_date.toISOString(),
                      Flight_Time: formatAMPM(flight_time),
                      AirLine: airlineName,
                      airline_id:parseInt(airline.split("|")[0]),
                      airline_type:airlineType,
                      Airport: airport,
                      Destination: destination,
                      Discount: "0",
                      Received: receiving_cash,
                      Base64_ImagePassport:PassportImage.split('base64,')[1],
                      Agent_ID: salesAgent_ID + "",
                      Agent_Name: salesAgent_Name,
                      ConsentForm: consentForm.split('base64,')[1],
                      Picture_String:PatientImage.split('base64,')[1]
                    }
                    console.log(SearchFormData)
            
                    var vSearchStr = JSON.stringify(SearchFormData);
                    var url = "https://reports.mpl-labs.pk:8443/4DACTION/BookingInvoiceCreateCovidQ"

                    fetch(url, {
                      method: "POST",
                      'Content-Type': "application/json",
                      body: vSearchStr
                    }).then((res) => res.json()).then((response) => {
                      console.log(response)
                      if (response[0].Invoice_Status == 'Successful') {
                        alert("Invoice : MPL-" + response[0].Invoice_No + " Successfully Created")
                        // localStorage.setItem('patient_mobile', "")
                        // localStorage.setItem("patient_name", "")
                        // localStorage.setItem('patient_cnic',"")
                        // window.location.href = "https://booking.mpl-labs.pk/Invoices"

                      }
                      else if (response[0].Invoice_Status == "Invoice Already Exist") {
                        alert("Invoice already Exist!!")
                        setSaveInvoice(false)
                      }
                      else {
                        alert("Sorry Failed !!")

                        setSaveInvoice(false)
                      }
                    })

                  } else {
                    var user_id = localStorage.getItem('user_id')
                    var branch_id = localStorage.getItem('branch_id')
                    var selectedpanel = ""
                    if (selectedValue=="Normal") {
                      selectedpanel = ""
                    } else {
                      selectedpanel = selectedValue
                    }
                    var salesAgent_ID = ""
                    var salesAgent_Name = ""
                    if (salesAgent != "Select") {
                      salesAgent_ID = salesAgent
                      salesAgent_Name = salesAgentNameList[salesAgent_ID]
                    }
                    var receiving_cash = total_bill + ""
                    if (localStorage.getItem('org_branch_id') == '0' || localStorage.getItem('org_branch_id') == '5') {
                      if (receiving == '' || amount != parseInt(receiving)) {
                        var result = window.confirm('Are you Sure ?. Balance will be due !! ')
                        if (result) {
                          if (receiving == '') {
                            receiving_cash = "0"
                          } else {
                            receiving_cash = receiving
                          }
                        } else {
                          setSaveInvoice(false)
                          return
                        }
                      }
                    }
                    var SearchFormData = {
                      Branch_ID: branch_id,
                      User_ID: user_id,
                      Ref_No: "",
                      ServiceID: "",
                      Panel_ID: selectedpanel=="Normal" ? "" : selectedpanel ,
                      Patient_ID: id,
                      Ticket_No: "",
                      Airport: "",
                      Flight_No: "",
                      Flight_Date: "",
                      Flight_Time: "",
                      AirLine: "",
                      airline_id:0,
                      airline_type:"",
                      Destination: "",
                      Discount: "0",
                      Received: receiving_cash,
                      Agent_ID: salesAgent_ID + "",
                      Agent_Name: salesAgent_Name,
                      ConsentForm: ""
                    }

                    var vSearchStr = JSON.stringify(SearchFormData);
                    console.log(vSearchStr)
            
                    var url = "https://reports.mpl-labs.pk:8443/4DACTION/BookingInvoiceCreateCovidQ"

                    fetch(url, {
                      method: "POST",
                      'Content-Type': "application/json",
                      body: vSearchStr
                    }).then((res) => res.json()).then((response) => {
                      console.log(response)
                      if (response[0].Invoice_Status == 'Successful') {
                        alert("Invoice : MPL-" + response[0].Invoice_No + " Successfully Created")
                        localStorage.setItem('patient_mobile', "")
                        localStorage.setItem("patient_name", "")
                        localStorage.setItem("patient_cnic", "")
                        window.location.href = "https://booking.mpl-labs.pk/Invoices"

                      }
                      else if (response[0].Invoice_Status == "Invoice Already Exist") {
                        alert("Invoice already Exist!!")
                        setSaveInvoice(false)
                      }
                      else {
                        alert("Sorry Failed !!")

                        setSaveInvoice(false)
                      }
                    })
                  }


          }}
        >Generate Invoice</Button>
        :
        null
        }
          </Grid>
        <Grid xs={4} sm={4} md={4} lg={4}></Grid>
        </Grid>
               </div>;
    default:
      return "unknown step";
  }
}

const LinaerStepper = () => {
  const classes = useStyles();
  const methods = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      nickName: "",
      emailAddress: "",
      phoneNumber: "",
      alternatePhone: "",
      address1: "",
      address2: "",
      country: "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
    },
  });
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();

  const isStepOptional = (step) => {
    return step === 100;
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = (data) => {
    localStorage.setItem('price','false')
    if (activeStep == steps.length - 1) {
      
          setActiveStep(activeStep + 1);

    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    localStorage.setItem('price','false')
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };
  useEffect(()=>{
      if( (localStorage.getItem("patient_cnic")!=undefined && localStorage.getItem("patient_cnic")!="" && localStorage.getItem("patient_cnic")!=null ) || (localStorage.getItem("patient_name")!=undefined && localStorage.getItem("patient_name")!="" && localStorage.getItem("patient_name")!=null && localStorage.getItem("patient_mobile")!=undefined && localStorage.getItem("patient_mobile")!="" && localStorage.getItem("patient_mobile")!=null) ){
        handleNext()
      }

  },[])

  // const onSubmit = (data) => {
  //   console.log(data);
  // };
  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography
                variant="caption"
                align="center"
                style={{ display: "block" }}
              >
                optional
              </Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
          Thank You
        </Typography>
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleNext)}>
              {GetStepContent(activeStep)}

              <Button
                className={classes.button}
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                BACK
              </Button>
              {isStepOptional(activeStep) && (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                >
                  skip
                </Button>
              )}
              {
                 steps.length - 1 ==activeStep ? 
                 null
                 :
                 <Button
                 className={classes.button}
                 variant="contained"
                 style={{
                   backgroundColor:activeStep==0 ? "red": "blue",
                   color:"white"
                 }}
                 color={activeStep==0 ? "red": "primary"}
                 // onClick={handleNext}
                 type="submit"
               >
                 {activeStep === steps.length - 1 ? "Finish" : activeStep==0 ? "SKIP" : "Next"}
               </Button>

              }
            
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};



const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes()
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes
  return strTime;
}


// export default function Invoice() {
  

//   return (
//     <div className={classes.root}>
//       <form noValidate autoComplete="off">
//         <div style={{
//           margin: 'auto',
//           padding: 10,
//           textAlign: 'center',
//           width: "50%"

//         }}>
//           {localStorage.getItem('org_branch_id') != '0' ? <h3 style={{
//             padding: 10,
//             border: '1px solid black'
//           }}>{selectBranch[localStorage.getItem('branch_id')]}</h3> : null}
//         </div>
//         <div style={{
//           margin: 'auto',
//           padding: 10
//         }}>

//           {
//             localStorage.getItem('org_branch_id') == '0' ?
//               <FormControl variant="filled" className={classes.formControl} style={{ width: "30em" }} >
//                 <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
//                 <Select
//                   labelId="demo-simple-select-filled-label"
//                   id="demo-simple-select-filled"
//                   value={branch}
//                   onChange={(e) => {
//                     var result = window.confirm('Are you Sure ?. Your want to change Branch?')
//                     if (result) {
//                       setBranch(e.target.value)

//                       localStorage.setItem('branch_id', e.target.value)
//                       localStorage.setItem('branch_name', selectBranch[e.target.value])
//                       var branchInfo=BranchContactInfo[e.target.value]
//                       localStorage.setItem('branch_province',(branchInfo.Province+""))
//                       localStorage.setItem('branch_mobile',(branchInfo.MobileNo+""))
//                       localStorage.setItem('branch_network',(branchInfo.MobileService+""))

//                       window.location.reload()
//                     }

//                   }}
//                 >
//                   <MenuItem value={"0"} disabled>Select</MenuItem>
//                   {
//                     branches.map((item) => {
//                       return <MenuItem value={item.BranchID} key={item.BranchID}>{item.BranchName.split('MPL, ')[1]}</MenuItem>

//                     })
//                   }
//                 </Select>
//               </FormControl>
//               : null
//           }
//         </div>
//         <div>


//         </div>

       
      

//         {/* <div>
//           <Autocomplete
//             id="combo-box-demo"
//             options={lab_tests}
//             getOptionLabel={(option) => option.Test_Code.toUpperCase()}
//             onSelect={(e) => {
//               var test = e.target.value.toUpperCase();
//               if (test.trim() != "") {
//                 var result = selectOption[test];
//                 if (result != undefined) {
//                   setState((prevState) => {
//                     const data = [...prevState.data];
//                     if (!data.includes(result)) {
//                       data.push(result);
//                       var price = parseInt(result.net_cost) + total_bill;
//                       setTotal_Bill(price);
//                       return { ...prevState, data };
//                     }
//                     return { ...prevState, data };
//                   });
//                 }
//               }
//             }}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 style={{ width: "23em" }}
//                 label="Services"
//                 variant="outlined"
//               />
//             )}
//           />
//         </div> */}
//         <div>
//           <Grid container>
//             <Grid xs={1} sm={1} md={1} lg={1}></Grid>
//             <Grid xs={10} sm={10} md={10} lg={10}>
//             </Grid>
//             <Grid xs={1} sm={1} md={1} lg={1}></Grid>
//           </Grid>


//           {/* <Grid container>
//             <Grid xs={3} sm={3} md={3} lg={3}></Grid>
//             <Grid xs={3} sm={3} md={3} lg={3}>
//               <TextField
//                 required
//                 id="outlined-required"
//                 label="Amount"
//                 variant="outlined"
//               />
//             </Grid>
//             <Grid xs={3} sm={3} md={3} lg={3}>
//               <TextField
//                 required
//                 id="outlined-required"
//                 label="Discount"
//                 variant="outlined"
//               />
//             </Grid>

//             <Grid xs={3} sm={3} md={3} lg={3}></Grid>
//           </Grid> */}
         


//         </div>

//         {
//           saveInvoice ?
//             <div>
//               <center>
//                 Submitting.............
//           <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
//               </center>
//             </div>
//             :
//             null
//         }
//         <div style={{
//           position: "absolute",
//           width: "100%"
//         }}>
//           <Grid container >
//             <Grid xs={4} sm={4} md={4} lg={4}></Grid>
//             <Grid xs={4} sm={4} md={4} lg={4}></Grid>
//             <Grid xs={4} sm={4} md={4} lg={4} style={{

//             }}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 style={{
//                   textAlign: 'center',
//                   padding: "1em",
//                   fontSize: '1em'
//                 }}
//                 disabled={saveInvoice}
//                 className={classes.button}
//                 onClick={async () => {
//                   setSaveInvoice(true)
//                   var branch_id = localStorage.getItem('branch_id')
//                   if (CNIC == "1730141841783" || passport == "FV0151783") {
//                     var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", vSubject: " WANTED PATIENT", vTo: 'najam@fsgroup.pk, support@mpl-labs.pk , nadeem@mpl-labs.pk', booking_id: "1730141841783", Address: "", date: "", Email: "", Relation: "", PhoneNo: "", AlternativePhone: "", Selected_Tests: "", Total_Bill: "" }
//                     console.log(SearchFormData)
//                     var vSearchStr = JSON.stringify(SearchFormData);

//                     var url = "https://reports.mpl-labs.pk:8443/4DACTION/WebHomeSamplingACKeMail?vQuery=" + vSearchStr + "&vUID=180820006&vRole=Admin"

//                     fetch(url).then((res) => res.json()).then((response) => {
//                       console.log(response)
//                       alert("Contact MPL Administration")
//                       setSaveInvoice(false)
//                       return

//                     })
//                   }


//                   if (branch_id == "0") {
//                     alert('Kindly Select Branch!!')

//                     setSaveInvoice(false)
//                     return
//                   }
//                   if (branch_id == undefined || branch_id == null) {
//                     window.location.reload()
//                     return
//                   }

//                   if (National == 'true') {
//                     if (CNIC.trim() == '') {
//                       alert('Failed, CNIC is compulsory for COVID-19')

//                       setSaveInvoice(false)
//                       return
//                     }
//                   }

//                   if (total_bill == 0) {
//                     alert("Kindly Contact MPL, For Configuration of Respective Panel Price.")
//                     setSaveInvoice(false)
//                     return
//                   }

//                   if (name.trim() == '') {
//                     alert("Kindly Select Patient")

//                     setSaveInvoice(false)
//                     return
//                   }
//                   if (id.trim() == '') {
//                     alert("Kindly Select Patient")

//                     setSaveInvoice(false)
//                     return
//                   }
//                   if (mobile.trim() == '') {
//                     alert("Kindly Select Patient")

//                     setSaveInvoice(false)
//                     return
//                   }


//                   if (!NormalCase && (selectedValue == 'AR-01070' || selectedValue == 'AR-01066' || selectedValue == 'AR-01076' || selectedValue == "AR-01092" || selectedValue == "AR-01091" || selectedValue == 'AR-01033' || selectedValue == 'AR-01042' || selectedValue == 'AR-01037' || selectedValue == 'AR-01036' || selectedValue == 'AR-01044' || selectedValue == 'AR-01059' || selectedValue == 'AR-01068' || selectedValue == 'AR-01062' || selectedValue == 'AR-01063' || selectedValue == 'AR-01064' || selectedValue == 'AR-01065' || selectedValue == 'AR-01067' || selectedValue == 'AR-01069' || selectedValue == 'AR-01148')) {
//                     if (National == 'true') {
//                       if (CNIC.trim() == "" || passport.trim() == "") {
//                         alert('Failed, CNIC & Passport is compulsory for Passengers')
//                         setSaveInvoice(false)
//                         return
//                       }
//                     } else {
//                       if (passport.trim() == "") {
//                         alert('Failed, Passport is compulsory for Passengers')
//                         setSaveInvoice(false)
//                         return
//                       }
//                     }

//                     if (flight_time == 0) {
//                       alert('Kindly Select Flight Time!!')

//                       setSaveInvoice(false)
//                       return

//                     }
//                     if (airline.trim() == '') {
//                       alert('Invalid AirLine !')

//                       setSaveInvoice(false)
//                       return
//                     }
//                     if (ticket_no.trim() == '') {
//                       alert('Invalid Ticket Number!')

//                       setSaveInvoice(false)
//                       return
//                     }
//                     if (flight_date.getFullYear() == '1900') {
//                       alert('Invalid Flight Date!')

//                       setSaveInvoice(false)
//                       return

//                     }
//                     if (flight_no.trim() == '') {
//                       alert('Invalid Flight Number!')

//                       setSaveInvoice(false)
//                       return
//                     }
//                     if (flight_date == 'Invalid Date') {
//                       alert('Invalid Flight Date!')

//                       setSaveInvoice(false)
//                       return
//                     }
//                     if (flight_time == 'Invalid Date') {
//                       alert('Invalid Flight Time!')

//                       setSaveInvoice(false)
//                       return
//                     }
//                     if (flight_time == '') {
//                       alert('Invalid Flight Time!')

//                       setSaveInvoice(false)
//                       return
//                     }
//                     var current_date = new Date()
//                     const diffTime = Math.abs(flight_date - current_date);

//                     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
//                     if ((diffDays > 3 && selectedValue == 'AR-01033') || (selectedValue != 'AR-01033' && diffDays > 3)) {
//                       alert('Flight Date is more than Allocated Hours by Airline!')

//                       setSaveInvoice(false)
//                       return
//                     }

//                     if (airport.trim() == '') {
//                       alert('Invalid Airport!')

//                       setSaveInvoice(false)
//                       return
//                     }
//                     if (destination.trim() == '') {
//                       alert('Invalid Destination!')

//                       setSaveInvoice(false)
//                       return
//                     }
//                     flight_date.setHours(15)
//                     var user_id = localStorage.getItem('user_id')
//                     var branch_id = localStorage.getItem('branch_id')

//                     var salesAgent_ID = ""
//                     var salesAgent_Name = ""
//                     if (salesAgent != "Select") {
//                       salesAgent_ID = salesAgent
//                       salesAgent_Name = salesAgentNameList[salesAgent_ID]
//                     }
//                     var receiving_cash = total_bill + ""
//                     if (localStorage.getItem('org_branch_id') == '0' || localStorage.getItem('org_branch_id') == '5') {
//                       if (receiving == '' || amount != parseInt(receiving)) {
//                         var result = window.confirm('Are you Sure ?. Balance will be due !! ')
//                         if (result) {
//                           if (receiving == '') {
//                             receiving_cash = "0"
//                           } else {
//                             receiving_cash = receiving
//                           }
//                         } else {
//                           setSaveInvoice(false)
//                           return
//                         }

//                       }
//                     }

//                     if (selectedValue == "AR-01066" && consentForm == "") {
//                       alert("Kindly Upload Consent Form!!")
//                       setSaveInvoice(false)
//                       return
//                     }
//                     if ( PatientImage == "" && !turkishImageBoolean   &&  ( selectedValue == 'AR-01066' )) {
//                       alert("Kindly Upload Patient Image!!")
//                       setSaveInvoice(false)
//                       return
//                     }
//                     if (PassportImage == "" && !turkishPassportBoolean && (selectedValue == 'AR-01070' || selectedValue == 'AR-01066' || selectedValue == 'AR-01076' || selectedValue == "AR-01092" || selectedValue == "AR-01091" || selectedValue == 'AR-01033' || selectedValue == 'AR-01042' || selectedValue == 'AR-01037' || selectedValue == 'AR-01036' || selectedValue == 'AR-01044' || selectedValue == 'AR-01059' || selectedValue == 'AR-01068' || selectedValue == 'AR-01062' || selectedValue == 'AR-01063' || selectedValue == 'AR-01064' || selectedValue == 'AR-01065' || selectedValue == 'AR-01067' || selectedValue == 'AR-01069' || selectedValue == 'AR-01148')) {
//                       alert("Kindly Upload Passport Image!!")
//                       setSaveInvoice(false)
//                       return
//                     }
//                     if (SamplingPicture == "" && (selectedValue == 'AR-01070' || selectedValue == 'AR-01066' || selectedValue == 'AR-01076' || selectedValue == "AR-01092" || selectedValue == "AR-01091" || selectedValue == 'AR-01033' || selectedValue == 'AR-01042' || selectedValue == 'AR-01037' || selectedValue == 'AR-01036' || selectedValue == 'AR-01044' || selectedValue == 'AR-01059' || selectedValue == 'AR-01068' || selectedValue == 'AR-01062' || selectedValue == 'AR-01063' || selectedValue == 'AR-01064' || selectedValue == 'AR-01065' || selectedValue == 'AR-01067' || selectedValue == 'AR-01069' || selectedValue == 'AR-01148')) {
//                       alert("Kindly Upload Sampling Picture !!")
//                       setSaveInvoice(false)
//                       return
//                     }
//                     var service_id = ""
//                     if (selectedValue == "AR-01066" & state.data.length == 2) {
//                       service_id = "SRV-880"
//                     }
//                     var SearchFormData = {
//                       Branch_ID: branch_id,
//                       User_ID: user_id,
//                       ServiceID: service_id,
//                       Ref_No: "",
//                       Panel_ID: selectedValue,
//                       Patient_ID: id,
//                       Ticket_No: ticket_no,
//                       Flight_No: flight_no,
//                       SampleTakingPicture:SamplingPicture.split('base64,')[1],
//                       Flight_Date: flight_date.toISOString(),
//                       Flight_Time: formatAMPM(flight_time),
//                       AirLine: airline,
//                       Airport: airport,
//                       Destination: destination,
//                       Discount: "0",
//                       Received: receiving_cash,
//                       Base64_ImagePassport:PassportImage.split('base64,')[1],
//                       Agent_ID: salesAgent_ID + "",
//                       Agent_Name: salesAgent_Name,
//                       ConsentForm: consentForm.split('base64,')[1],
//                       Picture_String:PatientImage.split('base64,')[1]
//                     }
//                     console.log(SearchFormData)
//                     var vSearchStr = JSON.stringify(SearchFormData);
//                     var url = "https://reports.mpl-labs.pk:8443/4DACTION/BookingInvoiceCreateCovidQ"

//                     fetch(url, {
//                       method: "POST",
//                       'Content-Type': "application/json",
//                       body: vSearchStr
//                     }).then((res) => res.json()).then((response) => {
//                       console.log(response)
//                       if (response[0].Invoice_Status == 'Successful') {
//                         alert("Invoice : MPL-" + response[0].Invoice_No + " Successfully Created")
//                         localStorage.setItem('patient_mobile', "")
//                         localStorage.setItem("patient_name", "")
//                         localStorage.setItem('patient_cnic',"")
//                         window.location.href = "https://booking.mpl-labs.pk/Invoices"

//                       }
//                       else if (response[0].Invoice_Status == "Invoice Already Exist") {
//                         alert("Invoice already Exist!!")
//                         setSaveInvoice(false)
//                       }
//                       else {
//                         alert("Sorry Failed !!")

//                         setSaveInvoice(false)
//                       }
//                     })

//                   } else {
//                     var user_id = localStorage.getItem('user_id')
//                     var branch_id = localStorage.getItem('branch_id')
//                     var selectedpanel = ""
//                     if (NormalCase) {
//                       selectedpanel = ""
//                     } else {
//                       selectedpanel = selectedValue
//                     }
//                     var salesAgent_ID = ""
//                     var salesAgent_Name = ""
//                     if (salesAgent != "Select") {
//                       salesAgent_ID = salesAgent
//                       salesAgent_Name = salesAgentNameList[salesAgent_ID]
//                     }
//                     var receiving_cash = total_bill + ""
//                     if (localStorage.getItem('org_branch_id') == '0' || localStorage.getItem('org_branch_id') == '5') {
//                       if (receiving == '' || amount != parseInt(receiving)) {
//                         var result = window.confirm('Are you Sure ?. Balance will be due !! ')
//                         if (result) {
//                           if (receiving == '') {
//                             receiving_cash = "0"
//                           } else {
//                             receiving_cash = receiving
//                           }
//                         } else {
//                           setSaveInvoice(false)
//                           return
//                         }
//                       }
//                     }
//                     var SearchFormData = {
//                       Branch_ID: branch_id,
//                       User_ID: user_id,
//                       Ref_No: "",
//                       ServiceID: "",
//                       Panel_ID: selectedpanel,
//                       Patient_ID: id,
//                       Ticket_No: "",
//                       Airport: "",
//                       Flight_No: "",
//                       Flight_Date: "",
//                       Flight_Time: "",
//                       AirLine: "",
//                       Destination: "",
//                       Discount: "0",
//                       Received: receiving_cash,
//                       Agent_ID: salesAgent_ID + "",
//                       Agent_Name: salesAgent_Name,
//                       ConsentForm: ""
//                     }

//                     var vSearchStr = JSON.stringify(SearchFormData);
//                     var url = "https://reports.mpl-labs.pk:8443/4DACTION/BookingInvoiceCreateCovidQ"

//                     fetch(url, {
//                       method: "POST",
//                       'Content-Type': "application/json",
//                       body: vSearchStr
//                     }).then((res) => res.json()).then((response) => {
//                       console.log(response)
//                       if (response[0].Invoice_Status == 'Successful') {
//                         alert("Invoice : MPL-" + response[0].Invoice_No + " Successfully Created")
//                         localStorage.setItem('patient_mobile', "")
//                         localStorage.setItem("patient_name", "")
//                         window.location.href = "https://booking.mpl-labs.pk/Invoices"

//                       }
//                       else if (response[0].Invoice_Status == "Invoice Already Exist") {
//                         alert("Invoice already Exist!!")
//                         setSaveInvoice(false)
//                       }
//                       else {
//                         alert("Sorry Failed !!")

//                         setSaveInvoice(false)
//                       }
//                     })
//                   }


//                 }}
//               >Save Invoice</Button>
//             </Grid>
//           </Grid>
//         </div>
//       </form>
//       <br />
//       <br />
//       <div style={{ width: "100%" }}>

//       </div>
//       <br />
//       <br />
//       <br />
//       <br />
//       <br />
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="simple-modal-title"
//         aria-describedby="simple-modal-description"
//       >
//         <div className={classes.paper}>
      
//         </div>
//       </Modal>


//       <Modal
//         open={openHist}
//         onClose={handleCloseHist}
//         aria-labelledby="simple-modal-title"
//         aria-describedby="simple-modal-description"
//       >
//         <div className={classes.paper}>
         
//         </div>
//       </Modal>
//     </div>
//   );
// }

export default LinaerStepper;
