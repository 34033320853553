import React, { Fragment } from 'react';
import { Text, View, StyleSheet , Image } from '@react-pdf/renderer';
import Invoice from '../../Invoice/invoice';



const InvoiceTableRow = ({ items, invoice }) => {
    const rows = items.map(item =>

        <View key={item.sn + "321323"}>
           
            <View style={{
                flexDirection: 'row',
                borderBottomColor: 'BLACK',
                borderBottomWidth: 0.5,
                alignItems: 'center',
                width: "100%",
                fontSize: 7,
                fontStyle: 'bold'
            }} key={item.sn}>
                <Text style={{

                    width: '10%',
                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 0.5,
                    height: '100%',
                    padding: 5,
                    fontSize: 7,
                }}>{item.sn}</Text>
                <Text style={{

                    width: '50%',
                    fontFamily: 'Helvetica-Bold',
                    textAlign: 'left',
                    borderLeftColor: 'black',
                    borderLeftWidth: 0.5,
                    fontSize: 6,
                    height: '100%',
                    padding: 5


                }}>{item.test_title}</Text>
                <Text style={{

                    width: '40%',
                    textAlign: 'left',
                    borderColor: 'black',
                    borderLeftWidth: 0.5,
                    borderRightWidth: 0.5,
                    fontSize: 6,
                    padding: 5,
                    height: '100%'


                }}>{item.reporting_date}</Text>

            </View>
            {
                item.flight_no ?
                    <View>
                        <View style={{
                            flexDirection: 'row',
                            marginTop: 5,
                            alignItems: 'center',
                            width: "100%",
                            height: 15,
                          
                        }} key={item.sn + "q123"}>
                            <View style={{

                                width: '57%',
                                textAlign: 'left',
                                height: '100%',
                                padding: 2,

                                fontSize: 7,
                            }}>
                               <Text style={{

width: '20%',
textAlign: 'left',
height: '100%',
padding: 2,

fontSize: 7,
}}></Text>
 <View style={{
    position:"absolute",
    width: '60%',
    textAlign: 'center',
    borderWidth: 0.5,
    borderRadius:15,
    padding:10,
    borderColor: 'black',
    flexDirection:'column'
}}>
<Text
style={{
    fontFamily: 'Helvetica-Bold',
    fontSize: 5
}}>
    {"Difference b/w Sample & Flight Time"}
</Text>

<Text
style={{
    fontFamily: 'Helvetica-Bold',
    fontSize: 14
}}>
   {invoice.Hours_Diff+" Hrs"}
</Text>

</View>
 <Text style={{

width: '20%',
textAlign: 'left',
height: '100%',
padding: 2,

fontSize: 7,
}}></Text>
                            </View>
                            <Text style={{

                                width: '15%',
                                textAlign: 'left',

                                height: '100%',

                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,
                                


                            }}>{"Flight No"}</Text>
                              <Text style={{

width: '3%',
textAlign: 'right',

height: '100%',

fontFamily: 'Helvetica-Bold',
fontSize: 9,



}}></Text>
                            <Text style={{

                                width: '25%',
                                height: '100%',
                                textAlign: 'left',


                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,

                                textOverflow: 'auto',
                                

                            }}>{item.flight_no}</Text>

                        </View>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: "100%",
                            height: 15
                        }} key={item.sn + "21"}>
                            <Text style={{

                                width: '57%',
                                textAlign: 'left',
                                height: '100%',
                                padding: 2,
                                fontSize: 7,
                            }}>

                            </Text>
                            <Text style={{

                                width: '15%',
                                textAlign: 'left',

                                height: '100%',
                                fontFamily: 'Helvetica-Bold',
                              
                                fontSize: 9,
                               


                            }}>{"Airline"}</Text>
                              <Text style={{

width: '3%',
textAlign: 'right',

height: '100%',

fontFamily: 'Helvetica-Bold',
fontSize: 9,



}}></Text>
                            <Text style={{

                                width: '25%',
                                height: '100%',
                                textAlign: 'left',

                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,

                                textOverflow: 'auto',
                               

                            }}>{item.Airline}</Text>

                        </View>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: "100%",
                            height: 15
                        }} key={item.sn + "21"}>
                            <Text style={{

                                width: '57%',
                                textAlign: 'left',
                                height: '100%',
                                padding: 2,
                                fontSize: 7,
                            }}>

                            </Text>
                            <Text style={{

                                width: '15%',
                                textAlign: 'left',

                                height: '100%',
                                fontFamily: 'Helvetica-Bold',
                              
                                fontSize: 9,
                               


                            }}>{"Ticket No"}</Text>
                              <Text style={{

width: '3%',
textAlign: 'right',

height: '100%',

fontFamily: 'Helvetica-Bold',
fontSize: 9,



}}></Text>
                            <Text style={{

                                width: '25%',
                                height: '100%',
                                textAlign: 'left',

                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,

                                textOverflow: 'auto',
                               

                            }}>{item.Ticket_no}</Text>

                        </View>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: "100%",
                            height: 15
                        }} key={item.sn + "21"}>
                            <Text style={{

                                width: '57%',
                                textAlign: 'left',
                                height: '100%',
                                padding: 2,
                                fontSize: 7,
                            }}>

                            </Text>
                            <Text style={{

                                width: '15%',
                                textAlign: 'left',

                                height: '100%',
                                fontFamily: 'Helvetica-Bold',
                              
                                fontSize: 9,
                               


                            }}>{"Flight Date"}</Text>
                              <Text style={{

width: '3%',
textAlign: 'right',

height: '100%',

fontFamily: 'Helvetica-Bold',
fontSize: 9,



}}></Text>
                            <Text style={{

                                width: '25%',
                                height: '100%',
                                textAlign: 'left',

                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,

                                textOverflow: 'auto',
                               

                            }}>{item.flight_date}</Text>

                        </View>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: "100%",
                            height: 15
                        }} key={item.sn + "43"}>
                            <Text style={{

                                width: '57%',
                                textAlign: 'left',
                                height: '100%',
                                padding: 2,
                                fontSize: 7,
                            }}>

                            </Text>
                            <Text style={{

                                width: '15%',
                                textAlign: 'left',

                                height: '100%',
                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,
                               
                              


                            }}>{"Flight Time"}</Text>
                              <Text style={{

width: '3%',
textAlign: 'right',

height: '100%',

fontFamily: 'Helvetica-Bold',
fontSize: 9,



}}></Text>
                            <Text style={{

                                width: '25%',
                                height: '100%',
                                textAlign: 'left',


                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,

                                textOverflow: 'auto',
                              

                            }}>{item.flight_time}</Text>

                        </View>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: "100%",
                            height: 15
                        }} key={item.sn + "43"}>
                            <Text style={{

                                width: '57%',
                                textAlign: 'left',
                                height: '100%',
                                padding: 2,
                                fontSize: 7,
                            }}>

                            </Text>
                            <Text style={{

                                width: '15%',
                                textAlign: 'left',

                                height: '100%',

                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,
                               


                            }}>{"Passport No"}</Text>
                              <Text style={{

width: '3%',
textAlign: 'right',

height: '100%',

fontFamily: 'Helvetica-Bold',
fontSize: 9,



}}></Text>
                            <Text style={{

                                width: '25%',
                                height: '100%',
                                textAlign: 'left',


                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,

                                textOverflow: 'auto',
                               

                            }}>{item.passport_no}</Text>

                        </View>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: "100%",
                            height: 20
                           
                        }} key={item.sn + "43"}>
                            <Text style={{

                                width: '57%',
                                textAlign: 'left',
                                height: '100%',
                                padding: 2,
                                fontSize: 7,
                            }}>

                            </Text>
                            <Text style={{

                                width: '15%',
                                textAlign: 'left',

                                height: '100%',

                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,
                              


                            }}>{"Destination"}</Text>
                             <Text style={{

width: '3%',
textAlign: 'right',

height: '100%',

fontFamily: 'Helvetica-Bold',
fontSize: 9,



}}></Text>
                            <Text style={{

                                width: '25%',
                                height: '100%',
                                textAlign: 'left',


                                fontFamily: 'Helvetica-Bold',
                                fontSize: 9,

                                textOverflow: 'auto',
                               

                            }}>{item.destination}</Text>

                        </View>
                    </View>
                    : null
            }
            <View key={item.sn + "3223"} style={{
                marginTop:15
            }}>


<Text style={{

    textAlign: 'left',
    padding: 2,
    width:"40%",
    borderTopColor:'black',
    borderTopWidth:0.5,
    fontSize: 7,
    color: 'black'
}}>
    {"User : "}<Text style={{
        fontFamily: 'Helvetica-Bold'
    }}>{item.User_Name}</Text>
</Text>
<Text style={{

textAlign: 'left',
padding: 2,
width:"40%",
borderTopColor:'black',
borderTopWidth:0.5,
borderBottomWidth:0.5,
fontSize: 7,
color: 'black'
}}>
{"Date Book : "}<Text style={{
    fontFamily: 'Helvetica-Bold'
}}>{item.invoice_date}</Text>{" at "}<Text style={{
    fontFamily: 'Helvetica-Bold'
}}>{item.invoice_time+" Hrs"}</Text>
</Text>

</View>
            <View key={item.sn + "4313"} style={{marginTop:15}}>


                <Text style={{

                    textAlign: 'left',
                    padding: 2,
                    fontFamily: 'Helvetica-Bold',
                    fontSize: 6.5,
                    color: 'black'
                }}>
                    {"I have read and verified my particulars as correct"}
                </Text>

            </View>
            <View key={item.sn + "4323"}>


                <Text style={{

                    textAlign: 'left',
                    padding: 2,
                    fontSize: 6.5,
                    color: 'black'
                }}>
                    {"Name : " + item.name}
                </Text>
                <Text style={{

                    textAlign: 'left',
                    padding: 2,
                    fontSize: 6.5,
                    color: 'black'
                }}>
                    {"CNIC : " + item.cnic}
                </Text>

                {
                    item.flight_no != ""
                        ?
                        <Text style={{

                            textAlign: 'left',
                            padding: 2,
                            fontSize: 6.5,
                            color: 'black'
                        }}>
                            {"Passport No : " + item.passport}
                        </Text>
                        : null
                }

                

            </View>

            <View key={item.sn + "542343"}>

                <Text style={{

                    width: '40%',
                    textAlign: 'left',
                    borderWidth: 0.5,
                    borderColor: 'black',
                    fontSize: 6.5,
                    padding: 5,


                }}>{"Signature"}</Text>

                <Text style={{

                    width: '60%',
                    textAlign: 'left',
                    height: '100%',
                    padding: 5,
                    fontSize: 7,
                }}>

                </Text>


            </View>

        </View>
    )
    return (<Fragment>
        {rows}
        

  <View style={{
           display:'flex',
           width:"100%",
           flexDirection:"column",
           alignItems:'center',
           position:'absolute',
           marginTop:"140em",
           marginLeft:"80em"
       }}>
          
             <View style={{
               width:"50%",
               flexDirection:"column",
               textAlign:"center",
               padding:5
           }}>
               <Text>Sampling Image                       Registration Image</Text>
               {
                   invoice.Base64_ImageSampleTaking=="data:image/png;base64,"
                   ?
                   <>
                   <Text
                   style={{
                    fontFamily: 'Helvetica-Bold',
                    fontSize: 7,
                    textAlign:"left"
                }}
                   >Sampling Image Not Found</Text>

{
                        invoice.Base64_PicturePatient=="data:image/png;base64,"
                        ?
                        <Text
                        style={{
                         fontFamily: 'Helvetica-Bold',
                         fontSize: 7,
                         textAlign:"left"
                     }}
                        >Registration Image Not Found</Text>
                        :
                        <Image style={{
                            width:100,
                            height:100,
                            marginLeft:10
                        }} src={invoice.Base64_PicturePatient} />
                }
                   </>
                   :
                   <View style={{
                       flexDirection:"row",
                       display:"flex"
                   }}>
                   <Image style={{
                    width:100,
                    height:120
                }} src={invoice.Base64_ImageSampleTaking} />
                
                    {
                        invoice.Base64_PicturePatient=="data:image/png;base64,"
                        ?
                        <Text
                        style={{
                         fontFamily: 'Helvetica-Bold',
                         fontSize: 7,
                         textAlign:"left"
                     }}
                        >Registration Image Not Found</Text>
                        :
                        <Image style={{
                            width:100,
                            height:100,
                            marginLeft:10
                        }} src={invoice.Base64_PicturePatient} />
                }
                
                </View>
               }
             
                 </View>
                 <View style={{
               width:"50%",
               flexDirection:"column",
               textAlign:"center",
               padding:5
           }}>
               <Text>Passport Image</Text>
               {
                   invoice.Base64_ImagePassport=="data:image/png;base64,"
                   ?
                   <Text
                   style={{
                    fontFamily: 'Helvetica-Bold',
                    fontSize: 9,
                    textAlign:"center"
                }}
                   >No Passport Image Found</Text>
                   :
                   <Image style={{
                    width:140,
                    height:140,
                    marginLeft:20
                }} src={invoice.Base64_ImagePassport} />
                   }

             </View>
             </View>
             </Fragment>)
};

export default InvoiceTableRow