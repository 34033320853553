import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceTableHeader from'./InvoiceTableHeader'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'

const styles = StyleSheet.create({
    page: { 
        paddingTop:180,
        paddingBottom:40,
        fontSize: 8,
        lineHeight: 1.5,
        flexDirection: 'column'
    },
    box: { width: '100%', marginBottom: 30, borderRadius: 5 },
    pageNumbers: {
      position: 'absolute',
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: 'center'
    },
    
    logo: {
        width: 180,
        height: 100
    },
    barcode: {
        width: 150,
        height: 40
    },
    titleContainer:{
      marginTop: 10
  },
  reportTitle:{
      color: 'black',
      fontSize: 10,
      width:"100%",
      fontFamily:'Helvetica-Bold',
      textAlign: 'center',
      textDecoration:'underline'
  }
  });
  const date=new Date()
  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear()
    ].join('/');
};
var time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  const Invoice=({invoice})=> (
    <Document>
      <Page style={styles.page} size="A4" wrap={true} >
      <View fixed style={{
                width: '100%', 
                display: 'flex',
                 flexDirection: 'row', 
                 top: 0, 
                 position: 'absolute',
                paddingLeft: 10,
                alignItems:"center",
                paddingRight: 10,
                paddingTop: 10
            }} >
               <View style={{display: 'flex', flexDirection: 'column' , marginLeft:10}}>
                  <Text style={{ 
                    fontFamily:'Helvetica-Bold',
                    fontSize:7
                    }}>Date</Text>
                  <Text style={{fontFamily:'Helvetica', color:'grey'}}>{date.ddmmyyy()}</Text>
                  <Text style={{fontFamily:'Helvetica-Bold', fontSize:7}}>Time</Text>
                  <Text style={{fontFamily:'Helvetica', color:'grey'}}>{time}</Text>
                </View>
                <View style={{
                  flex:'1',
                  flexDirection:'column',
        marginLeft:150
                }}>
                <Image style={styles.logo} src={`${process.env.PUBLIC_URL}/static/images/mpl-logo.png`} />
            
    </View>
    
 <View style={{
  display:'flex',
  position:'absolute',
  width:'100%',
  marginLeft:10,
  paddingTop:210
 }}>
   <View style={{
                  flex:'1',
                  flexDirection:'row'
     
                }}>
    <View style={styles.titleContainer}>
              <Text style={styles.reportTitle}>{"COVID-19 PCR BOOKING SUMMARY ("+invoice.from+") to ("+invoice.to+")"}</Text>
    </View>
    </View>
 <InvoiceTableHeader />
   </View>
            
               
            </View>

            <View style={{width:'100%',paddingRight:10, paddingLeft:10}} 
           
           >
               <InvoiceItemsTable invoice={invoice.items} />
           </View>



        
      </Page>
    </Document>
  );
  
export default Invoice