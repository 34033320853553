import React ,{useEffect,Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';

import Invoice from './PDF/Invoice.js'

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { DatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';




import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import {PDFViewer} from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet ,PDFDownloadLink} from '@react-pdf/renderer';

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import RefreshIcon from '@material-ui/icons/Refresh';

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';






function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}


const columns = 
  [{ id: 'id', label: 'Invoice ID' },
  { id: 'name', label: 'Patient Name' },
  { id: 'cnic', label: 'CNIC' },
  { id: 'title', label: 'Test Name' },
  { id: 'date', label: 'Booking Date/Time' },
  { id: 'branch', label: 'Branch' },
  { id: 'status', label: 'Status' }
]
function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
function to24Hours(d) {
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());
 return h + ":" + m + ":" + s;
}
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime (ms) {
  var seconds = (ms/1000);
  var minutes = parseInt(seconds/60, 10);
  seconds = seconds%60;
  var hours = parseInt(minutes/60, 10);
  minutes = minutes%60;
  
  return [hours,minutes,1];
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function Summary() {
  const classes = useStyles();
  var date=new Date()
  var temp_role=localStorage.getItem('role')
  

  const [selected,setSelected]=React.useState([])
  
  const [OpenCovidTemplate,setCovidTemplate]=React.useState(false)

  const [OpenOfficialCovidTemplate,setOfficialCovidTemplate]=React.useState(false)

  const [patient_invoice,setPatientInvoice]=React.useState(false)
  date.setHours(0,0,0)
  const [from,setFrom] = React.useState(date)
  const [to, setTo] = React.useState(new Date())
  const [width, height] = [window.innerWidth, window.outerWidth];
  const [panel,setPanel]=React.useState('All')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows,setRows]= React.useState([]);

  const [org_rows,setOrg_Rows]= React.useState([]);
  const [loading,setLoading]=React.useState(true)

  const [covidButton,setCovidButton]=React.useState(false)

  const [panelList,setpanelList]=React.useState([])
  const [laborder,setLabOrder]=React.useState(false)
  const [show,setShow]=React.useState(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branches,setBranches]=React.useState([])
  const [branch,setBranch]=React.useState("")
  const [selectBranch,setSelectBranch]=React.useState([])
  const [state, setState] = React.useState({
    status:'All'
  });
  // Booking Filter
  const [BookedBy,setBookedBy]=React.useState([])
  const [SelectedBookedBy,setSelectedBookBy]=React.useState("Select")

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    setTimeout(() => {
      window.addEventListener("beforeunload", function (e) {
        localStorage.clear()
         });
    }, 60000*20);
    var vSearchStr={
      "User_ID" :localStorage.getItem('user_id'),
      "Menu_Option" :"Covid Summary Export"
  }
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebCheckUserStatusActiveAccess",{
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify(vSearchStr)
    }).then(res=>res.json()).then((res)=>{
      if(res[0].Option_Status=="Authorized"){
        
      }else{
        localStorage.clear()
        window.location.href = "https://booking.mpl-labs.pk/login"
      }
    })

    if(localStorage.getItem('org_branch_id')==null || localStorage.getItem('org_branch_id')==undefined){
      localStorage.clear()
      window.location.href="https://booking.mpl-labs.pk/login"
    }
    if(localStorage.getItem('user_id')==null || localStorage.getItem('user_id')==undefined){
      localStorage.clear()
      window.location.href="https://booking.mpl-labs.pk"
    }
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res=>res.json()).then((response)=>{
      setBranches(response)
      
    })
    var patient_id= localStorage.getItem('patient_id')
    var role=localStorage.getItem('role')
  
   
   
    var branchID=localStorage.getItem('branch_id')
   
    var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID:branchID }
    var vSearchStr = JSON.stringify(SearchFormData);
    var user_id=patient_id+""
    var vRole=role
    if(localStorage.getItem("panel_code")){
      user_id=localStorage.getItem("panel_code")
      vRole="Panel"
    }
    

    var url = "https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetTestListwithStatus?vQuery=" + vSearchStr + "&vUID=" + user_id + "&vRole=" + vRole
  
    fetch(url).then((res)=>res.json())
     .then((result)=>{
      var the_rows=[]
      // Booker Filter
      var BookByArr=[]
      
      var from_date =( from.ddmmyyy())+" "+(from.getHours()+":"+from.getMinutes()+":"+from.getSeconds())
      var to_date = (to.ddmmyyy())+" "+(to.getHours()+":"+to.getMinutes()+":"+to.getSeconds())
     console.log(from_date)
     console.log(to_date)
      result.map((data)=>{
        var br_title=""
      var report_date=new Date(data.ACCESS_DATE)
      report_date.setHours(msToTime(data.ACCESS_TiME)[0],msToTime(data.ACCESS_TiME)[1],msToTime(data.ACCESS_TiME)[2])
      if(report_date>=from && report_date<=to){
        if(data.T_TiTLE!="Covid-19 RNA by PCR"){
          return
        }
      if(data.B_TiTLE=="METROPOLE"){
        br_title="Islamabad"
      }else{
       
          br_title=data.B_TiTLE.split('MPL,')[1]
        
    
      }
      var booking_date = new Date(data.ACCESS_DATE).ddmmyyy()
      var booking_time = msToTime(data.ACCESS_TiME)
      var obj={
        id:data.Invoice_ID,
        name:data.NAME_F+" "+data.NAME_L,
        title:data.T_TiTLE,
        status:data.Test_Status,
        cnic:data.CNIC,
        date:booking_date+" "+(booking_time[0])+":"+(booking_time[1])+":"+(booking_time[2]),
        mobile:data.Mobile_No,
        passport:data.Passport_No,
        reference_no:data.Reference_No,
        code:data.T_CODE,
        bookedBy:data.User_Name,
        Panel_Code:data.Panel_Code,
        Panel_Name:data.Panel_Name,
        branch:br_title,
        dateObject:new Date(data.ACCESS_DATE)
       
      }
      if(BookByArr.indexOf(data.User_Name)==-1){
           
        BookByArr.push(data.User_Name) 
        }
     
      the_rows.push(obj)
    }
       

      })
      setBookedBy(BookByArr)
      setRows(the_rows)
      setOrg_Rows(the_rows)
      setLoading(false)
      setSelected({
        from:from_date,
        to:to_date,
        items:the_rows
      })
     })
     fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetAllPanelsList").then((res)=>res.json())
      .then((result)=>{
        setpanelList(result)
      }) 
if(localStorage.getItem('org_branch_id')=='0'){
  fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res=>res.json()).then((response)=>{
  setBranches(response)
  var obj={}
  response.map((item)=>{
    obj[item.BranchID]=item.BranchName
  })
  setSelectBranch(obj)

  setBranch(localStorage.getItem('branch_id'))
})
}


  },[])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes(); 
    var hh = this.getHours();

    return [
        (hh > 9 ? '' : '0') + hh,
        (mm > 9 ? '' : '0') + mm
    ].join(':');
};

Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear()
    ].join('/');
};
  return (
    <>
    <Paper className={classes.root}>
    <div style={{padding:'1em', textAlign:'center'}}>
      {localStorage.getItem('org_branch_id')=='0' ? <h3 style={{color:'red'}}>Branch Selection will change the Booking, Invoicing and Reporting Branch</h3>:null}
      </div>
      <GridContainer style={{padding:'1em'}}>
                <GridItem xs={6} sm={6} md={3} lg={3}>
                
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
 <KeyboardDateTimePicker
        variant="inline"
        inputVariant="outlined"
        label="From"
        ampm={false}
        minDate={new Date().setMonth(new Date().getMonth()-1)}
        format="dd/MM/yyyy HH:mm"
        value={from}
        maxDate={to}
        InputAdornmentProps={{ position: "start" }}
        onChange={date=> {
          setFrom(date)}}
      />
      </MuiPickersUtilsProvider>
                </GridItem>
                {
                  width<600
                  ?
<GridItem xs={6} sm={6} md={0} lg={0}>

</GridItem>:""
                }
                
                <GridItem xs={6} sm={6} md={3} lg={3}>
                 
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
 <KeyboardDateTimePicker
        variant="inline"
        inputVariant="outlined"
        label="To"
        ampm={false}
        format="dd/MM/yyyy HH:mm"
        maxDate={new Date()}
        value={to}
        minDate={from}
        InputAdornmentProps={{ position: "start" }}
        onChange={date=> setTo(date)}
      />
      </MuiPickersUtilsProvider>
                  
                </GridItem>

                </GridContainer>
                <GridContainer style={{padding:'1em'}}>
                {
                  width<600
                  ?
<GridItem xs={6} sm={6} md={0} lg={0}>

</GridItem>:""
                }
                <GridItem xs={6} sm={6} md={2} lg={2}>
                <FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
            value={state.status}
          onChange={handleChange}
          inputProps={{
            name: 'status',
            id: 'filled-age-native-simple',
          }}
        >
          <MenuItem aria-label="Select" value="All" >All</MenuItem>
          <MenuItem value={'Just Booked'}>Just Booked</MenuItem>
          <MenuItem value={'Only Phlebotomy Done'}>Only Phlebotomy Done</MenuItem>
          <MenuItem value={'Lying Pending Results'}>Lying Pending Results</MenuItem>
          <MenuItem value={'Results Done But not Signed'}>Results Done But not Signed</MenuItem>
          <MenuItem value={'Results Ready'}>Results Ready</MenuItem>
        </Select>
      </FormControl>
                  </GridItem>
                  {
               localStorage.getItem('org_branch_id')=='0' ?
              <>
               <GridItem xs={12} sm={12} md={2} lg={2}  >
               <FormControl variant="filled" className={classes.formControl}  style={{width:"10em"}} >
               <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
               <Select
                 labelId="demo-simple-select-filled-label"
                 id="demo-simple-select-filled"
                 value={branch}
                 onChange={(e)=>{
                   var result=window.confirm('Are you Sure ?. Your want to change Branch?')
                  if(result){
                    setBranch(e.target.value)
                  localStorage.setItem('branch_id',e.target.value)
                  localStorage.setItem('branch_name',selectBranch[e.target.value])
                  window.location.reload()
                  }
                  
                 }}
               >
                 <MenuItem value={"0"} disabled>Select</MenuItem>
                 <MenuItem value={"All"} >All</MenuItem>
                    {
                      branches.map((item)=>{
                       return <MenuItem value={item.BranchID} key={item.BranchID}>{item.BranchName.split('MPL, ')[1]}</MenuItem>
                         
                      })
                    }
               </Select>
             </FormControl>
             </GridItem>
             <GridItem xs={12} sm={12} md={2} lg={2}  >
               <FormControl variant="filled" className={classes.formControl}  style={{width:"10em"}} >
               <InputLabel id="demo-simple-select-filled-label">User</InputLabel>
               <Select
                 labelId="demo-simple-select-filled-label"
                 id="demo-simple-select-filled"
                 value={SelectedBookedBy}
                 onChange={(e)=>{
                    setSelectedBookBy(e.target.value)
                 }}
               >
                 <MenuItem value={"0"} disabled>Select</MenuItem>
                    {
                      BookedBy.map((item)=>{
                       return <MenuItem value={item} key={item}>{item}</MenuItem>
                         
                      })
                    }
               </Select>
             </FormControl>
             </GridItem>
              </>
             :null
             }
                  <GridItem xs={12} sm={12} md={2} lg={2} style={{margin:'auto', flex:"1", flexDirection:"column"}} >
                    
                  <Button variant="contained" color="primary" disableElevation disabled={loading}
                  style={{
                    marginBottom:5
                  }}
                  onClick={()=>{
                    if(to=="Invalid Date" || from =="Invalid Date"){
                      alert("Invalid Date")
                      return
                    }
                    setPage(0)
                   document.getElementById('search_data').value=""
                    var role=localStorage.getItem('role')
                    var patient_id= localStorage.getItem('patient_id')
                    setLoading(true)
                    var from_date =( from.ddmmyyy())+" "+(from.getHours()+":"+from.getMinutes()+":"+from.getSeconds())
                    var to_date = (to.ddmmyyy())+" "+(to.getHours()+":"+to.getMinutes()+":"+to.getSeconds())
               
                    var  branchID=localStorage.getItem('branch_id')
                    var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID:branchID }
                    var vSearchStr = JSON.stringify(SearchFormData);
                  
                    var user_id=patient_id+""
                var vRole=role
                if(localStorage.getItem("panel_code")){
                  user_id=localStorage.getItem("panel_code")
                  vRole="Panel"
                }
                
            
                var url = "https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetTestListwithStatus?vQuery=" + vSearchStr + "&vUID=" + user_id + "&vRole=" + vRole
                  
                    fetch(url).then((res)=>res.json())
     .then((result)=>{
      
      var the_rows=[]
       // Booker Filter
       var BookByArr=[]
     
      var from_date =( from.ddmmyyy())+" "+(from.getHours()+":"+from.getMinutes()+":"+from.getSeconds())
      var to_date = (to.ddmmyyy())+" "+(to.getHours()+":"+to.getMinutes()+":"+to.getSeconds())
   
      var name=localStorage.getItem('patient_name')
      result.map((data)=>{
        var br_title=""
      
        var report_date=new Date(data.ACCESS_DATE)
        report_date.setHours(msToTime(data.ACCESS_TiME)[0],msToTime(data.ACCESS_TiME)[1],msToTime(data.ACCESS_TiME)[2])
    
        if(report_date>=from && report_date<=to){
          if(data.T_TiTLE!="Covid-19 RNA by PCR"){
            return
          }
          if(data.B_TiTLE=="METROPOLE"){
            br_title="Islamabad"
          }else{
            
              br_title=data.B_TiTLE.split('MPL,')[1]
            
        
          }
          var booking_date = new Date(data.ACCESS_DATE).ddmmyyy()
        var booking_time = msToTime(data.ACCESS_TiME)
        var obj={
          id:data.Invoice_ID,
          name:data.NAME_F+" "+data.NAME_L,
          title:data.T_TiTLE,
          status:data.Test_Status,
          cnic:data.CNIC,
          date:booking_date+" "+(booking_time[0])+":"+(booking_time[1])+":"+(booking_time[2]),
          mobile:data.Mobile_No,
          passport:data.Passport_No,
          reference_no:data.Reference_No,
          code:data.T_CODE,
          bookedBy:data.User_Name,
          Panel_Code:data.Panel_Code,
          Panel_Name:data.Panel_Name,
          branch:br_title,
          dateObject:new Date(data.ACCESS_DATE)
         
        }
        if(BookByArr.indexOf(data.User_Name)==-1){
           
          BookByArr.push(data.User_Name) 
          }
        if(state.status=="All" && (SelectedBookedBy=="Select" ||SelectedBookedBy=="All" || SelectedBookedBy==data.User_Name)){
          the_rows.push(obj)
          
        
        }else{
          if(state.status.trim()==data.Test_Status.trim() && (SelectedBookedBy=="Select" || SelectedBookedBy=="All" || SelectedBookedBy==data.User_Name)){
            the_rows.push(obj)
          
          }
        }  
          
        }

       
      })
     
      setBookedBy(BookByArr)
      setRows(the_rows)
      setOrg_Rows(the_rows)
      setLoading(false)
      setPage(0)

      setSelected({
        from:from_date,
        to:to_date,
        items:the_rows
      })
     })
                    
                  }}
                  >
                    Seach<SearchIcon />
                  </Button>
                  <Button variant="contained" color="secondary" disableElevation disabled={loading}
                  onClick={()=>{
                  setShow(true)
                  }} >
                    Export Summary
                  </Button>
                  </GridItem>
              </GridContainer>
        <div align="right">
               
               <TextField id="standard-basic" id="search_data" label="Search by Invoice ID, Passport No , Reference No , CNIC , Patient Name , Mobile No , Test Name and Test Code"
             style={{maxWidth:'50em'}}
              fullWidth={true}
              
               onChange={async (e)=>{
                   var lab_tests=[]
                   var text=e.target.value
                  var data=await org_rows.map((item)=>{
                    if(item.title=="Covid-19 RNA by PCR"){
                      if(item.cnic.includes(text)){
                        lab_tests.push(item)
                        return
                      }
                      if(item.passport.toLowerCase().includes(text.toLowerCase())){
                        lab_tests.push(item)
                        return
                      }
                      if(item.reference_no.toLowerCase().includes(text.toLowerCase())){
                        lab_tests.push(item)
                        return
                      }
                    }
                    if(item.mobile.includes(text.toLowerCase())){
                      lab_tests.push(item)
                      return
                   
                  }
                
                      if(item.title.toLowerCase().includes(text.toLowerCase())){
                          lab_tests.push(item)
                          return
                       
                      }
                     
                      if(item.name.trim().toLowerCase().includes(text.trim().toLowerCase())){
                        lab_tests.push(item)
                        return
                     
                    }
                    if(item.code.toLowerCase().includes(text.toLowerCase())){
                      lab_tests.push(item)
                      return
                   
                  }
                  if(item.id.toLowerCase().includes(text.toLowerCase())){
                    lab_tests.push(item)
                    return
                 
                }
                      
                      return item
              
                  })
                  Promise.all(data).then((item)=>{
                   
                     setRows(lab_tests)
                     
                  })
                  
            }}              
               />
             
            
               </div>
               
     {loading ? 
     <center>
     <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
     </center>
     :
     <>
      <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code+Math.random()} >
                {columns.map((column) => {
                  const value = row[column.id];
                  var status_color= value=='Just Booked' ? 'brown' : value=='Only Phlebotomy Done' ? 'red' :
value=='Lying Pending Results' ? 'purple' : value=='Results Done But not Signed' ? 'blue' : value=='Results Ready' ? 'green' :'' 

                  return (
                  <>
                      {status_color.trim()!='' ?  
                      <TableCell key={column.id} align={column.align} style={{backgroundColor:status_color, color:'white'}} >
                     <i><b>{value}</b></i>
                                      </TableCell>
                      :   <TableCell key={column.id} align={column.align} >
                      {value}
                                       </TableCell>}
                   </>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
    </>
     }
  
  <GridContainer>
      <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}
                  open={show}
                  TransitionComponent={Transition}
                  keepMounted
                  maxWidth={'xl'}
                  fullWidth={true}
                  onClose={() => setShow(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
              <h3><b>COVID-19 LAB BOOKING SUMMARY</b></h3>
              
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                    
                  >
                    <center>
                  <Fragment>
         {
           show?    <PDFViewer width="1000" height="1000" className="app" >
           <Invoice invoice={selected}/>
       </PDFViewer>: null
         }
        </Fragment>
        </center>
                   </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => setShow(false)}
                      color="danger"
                      simple
                    >
                      <b>Close</b>
                    </Button>
                  </DialogActions>
                </Dialog>
        </GridContainer>
    </Paper>
    
   
  </>
  );
}
