import React from 'react';
import {View, StyleSheet, Text, Image  } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 3,
        width:"380em",
        borderColor: 'black'
    },
});

  const InvoiceItemsTable = ({invoice}) => (
  
  <View>
      <InvoiceTitle item={invoice} />
   <View style={styles.tableContainer}>
     
   <InvoiceTableHeader />
  
   <InvoiceTableRow items={invoice.reports} key={invoice.name} invoice={invoice} />
                   

</View>
             
</View>
 
  
  );
  
  export default InvoiceItemsTable