import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import HelpIcon from '@material-ui/icons/Help';
import { isMobile } from 'react-device-detect';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';

import Invoice from './PDF/Invoice.js'

import LabOrder from './Lab_Order/Invoice.js'


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import RefreshIcon from '@material-ui/icons/Refresh';

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';


import CovidTemplate from './Covid_PDF/CovidTemplate'

import OfficialCovidTemplate from './Official_Template/OfficialCovidTemplate'

import EditIcon from '@material-ui/icons/Edit';
//import EditPatientByInvoice from './EditPatientByInvoice/index'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}
var temp = localStorage.getItem('org_branch_id') == '0' ?

  [{ id: 'patient_invoice', label: 'COVID-19 Invoice' },
  { id: 'invoice', label: 'Invoice' },
  { id: 'lab_order', label: 'Lab Order' },
  { id: 'barcode', label: 'Print Barcode' }

  ]
  :
  (localStorage.getItem('org_branch_id') == '1' || localStorage.getItem('org_branch_id') == '42' || localStorage.getItem('org_branch_id') == '3')
    ?
    [
      { id: 'patient_invoice', label: 'View Patient Invoice' },
      { id: 'lab_order', label: 'View Lab Order' }
    ]
    :
    [
      { id: 'patient_invoice', label: 'View Patient Invoice' },
      { id: 'lab_order', label: 'View Lab Order' },
      { id: 'barcode', label: 'Print Barcode' }
    ]

const columns =
  [{ id: 'id', label: 'Invoice ID' },
  { id: 'name', label: 'Patient Name' },
  { id: 'title', label: 'Test Name' },
  { id: 'code', label: 'Test Code' },
  { id: 'branch', label: 'Branch' },
  { id: 'status', label: 'Status' },
  ...temp
  ]
function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
function FlightToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ':' + minutes;
}
function to24Hours(d) {
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());
  return h + ":" + m + ":" + s;
}
const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return addZero(hours) + ':' + addZero(minutes);
}

export default function Lab_Tests() {
  const classes = useStyles();
  var date = new Date()
  var temp_role = localStorage.getItem('role')


  const [selected, setSelected] = React.useState({
    reports: []
  })

  const [OpenCovidTemplate, setCovidTemplate] = React.useState(false)

  const [OpenOfficialCovidTemplate, setOfficialCovidTemplate] = React.useState(false)

  const [patient_invoice, setPatientInvoice] = React.useState(false)
  const [from, setFrom] = React.useState(date)
  const [to, setTo] = React.useState(new Date())
  const [width, height] = [window.innerWidth, window.outerWidth];
  const [panel, setPanel] = React.useState('All')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [BranchContactInfo,setBranchContactInfo]=React.useState({})
  const [covidRate,setCovidrate]=React.useState(localStorage.getItem("branch_province")==undefined || localStorage.getItem("branch_province")==null  ? "3,500" : localStorage.getItem("branch_province").trim()=="" ? "3,500" : (localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="federal" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="kpk" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="ajk" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="gb" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="balochistan") ? "5,000" : localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="punjab" ? "3,000" : localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="sindh" ? "4,500" : "3,500" )

  const [rows, setRows] = React.useState([]);

  const [selectedCovid, setSelectedCovid] = React.useState({
    reports: []
  })
  const [selectedOfficialCovidReport, setSelectedOfficialCovidReport] = React.useState({
    reports: []
  })

  const [org_rows, setOrg_Rows] = React.useState([]);
  const [loading, setLoading] = React.useState(true)

  const [covidButton, setCovidButton] = React.useState(false)

  var Focus = false
  const setFocus = (bool) => {
    setLoading(bool)
    Focus = bool
  }

  const [panelList, setpanelList] = React.useState([])
  const [laborder, setLabOrder] = React.useState(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branches, setBranches] = React.useState([])
  const [branch, setBranch] = React.useState("")
  const [selectBranch, setSelectBranch] = React.useState([])
  const [state, setState] = React.useState({
    status: 'All',
  });

  // Edit Patient Feature 25 Feb 2022
  const [patientEdit,setPatientEdit]=React.useState(false)
  const [OpenEdit,setOpenEdit]=React.useState(false)
  const [invoiceEdit,setinvoiceEdit]=React.useState("")

  var svgString2Image = (svgString, width, height, format, callback) => {

    var svgStringFormat = window.atob(svgString)
    console.log(svgStringFormat)
    var temp = svgStringFormat.split(`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">`)
    // set default for format parameter
    format = format ? format : 'png';
    // SVG data URL from SVG string
    var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp[1])));
    // create canvas in memory(not in DOM)
    var canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    var context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () { // async (happens later)
      // clear canvas
      context.clearRect(0, 0, width, height);
      // draw image with SVG data to canvas
      context.drawImage(image, 0, 0, width, height);
      // snapshot canvas as png
      var pngData = canvas.toDataURL('image/' + format);
      // pass png data URL to callbac
      callback(pngData)
    }; // end async
    image.src = svgData

  }

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    if(localStorage.getItem('org_branch_id')=='0' && localStorage.getItem('branch_id')=='0'){
      setLoading(false)
      alert("Please Select Branch")
      return
    }
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
        setBranches(response)
        var obj = {}
        var objBranchInfo={}
        response.map((item) => {
          obj[item.BranchID] = item.BranchName
          objBranchInfo[item.BranchID]={
            ...item
          }
        })
        setBranches(response)
        if(objBranchInfo!={}){
          var branchInfo=objBranchInfo[parseInt(localStorage.getItem('branch_id'))]
          console.log(branchInfo)
          if(branchInfo!=undefined){
          localStorage.setItem('branch_province',(branchInfo.Province+""))
          var rate=localStorage.getItem("branch_province")==undefined || localStorage.getItem("branch_province")==null  ? "3,500" : localStorage.getItem("branch_province").trim()=="" ? "3,500" : (localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="federal" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="kpk" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="ajk" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="gb" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="balochistan") ? "5,000" : localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="punjab" ? "3,000" : localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="sindh" ? "4,500" : "3,500"  
          setCovidrate(rate)
          }
        }

        setBranchContactInfo(objBranchInfo)
     
      }).then(()=>{

        setTimeout(() => {
          window.addEventListener("beforeunload", function (e) {
            localStorage.clear()
          });
        }, 60000 * 20);
        var vSearchStr = {
          "User_ID": localStorage.getItem('user_id'),
          "Menu_Option": "Covid Invoice Printing"
        }
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebCheckUserStatusActiveAccess", {
          method: "POST",
          'Content-Type': "application/json",
          body: JSON.stringify(vSearchStr)
        }).then(res => res.json()).then((res) => {
          if (res[0].Option_Status == "Authorized") {
          
          } else {
            localStorage.clear()
            window.location.href = "https://booking.mpl-labs.pk/login"
          }
        })
      vSearchStr = {
          "User_ID": localStorage.getItem('user_id'),
          "Menu_Option": "Covid Edit Patient"
        }
        //////////////////////////////////////////////////// Edit Feature of Covid Booking
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebCheckUserStatusActiveAccess", {
          method: "POST",
          'Content-Type': "application/json",
          body: JSON.stringify(vSearchStr)
        }).then(res => res.json()).then((res) => {
          if (res[0].Option_Status == "Authorized") {
            setPatientEdit(true)
          } else {
          }
        })
         //////////////////////////////////////////////////// Edit Feature of Covid Booking
    
        if (localStorage.getItem('org_branch_id') == null || localStorage.getItem('org_branch_id') == undefined) {
          localStorage.clear()
          window.location.href = "https://booking.mpl-labs.pk/login"
        }
        if (localStorage.getItem('user_id') == null || localStorage.getItem('user_id') == undefined) {
          localStorage.clear()
          window.location.href = "https://booking.mpl-labs.pk"
        }
       
        var patient_id = localStorage.getItem('patient_id')
        var role = localStorage.getItem('role')
    
        var from = new Date();
    
    
        var branchID = localStorage.getItem('branch_id')
    
        var to = new Date();
        var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID: branchID }
        var vSearchStr = JSON.stringify(SearchFormData);
        var user_id=patient_id+""
        var vRole=role
        if(localStorage.getItem("panel_code")){
          user_id=localStorage.getItem("panel_code")
          vRole="Panel"
        }
        
    
        var url = "https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetTestListwithStatus?vQuery=" + vSearchStr + "&vUID=" + user_id + "&vRole=" + vRole
    
        fetch(url).then((res) => res.json())
          .then((result) => {
            var the_rows = []
            result.map((data) => {
              var br_title = ""
    
              if (data.B_TiTLE == "METROPOLE") {
                br_title = "Islamabad"
              } else {
    
                br_title = data.B_TiTLE.split('MPL,')[1]
    
    
              }
              var obj = {
                id: data.Invoice_ID,
                name: data.NAME_F + " " + data.NAME_L,
                title: data.T_TiTLE,
                status: data.Test_Status,
                cnic: data.CNIC,
                mobile: data.Mobile_No,
                passport: data.Passport_No,
                reference_no: data.Reference_No,
                code: data.T_CODE,
                Panel_Code: data.Panel_Code,
                Panel_Name: data.Panel_Name,
                branch: br_title,
                dateObject: new Date(data.ACCESS_DATE),
                edit :<Button variant="contained" style={{backgroundColor:'red', color:'white'}} disableElevation onClick={()=>{
                  setinvoiceEdit(data.Invoice_ID)
                  setOpenEdit(true)
                }}>
                     <EditIcon />
                 </Button>,
                refresh: <center>
                  <Button variant="contained" style={{ backgroundColor: 'skyblue', color: 'white' }} disableElevation onClick={() => {
                    if (data.Test_Status == 'Results Ready') {
                      window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF_BlobRefresh/' + data.InV_AuX_ID)
                    } else {
                      alert('Results not Ready!!')
                    }
                  }}>
                    <  ReceiptIcon />
                  </Button>
                </center>
                ,
                barcode: <center>
                  <Button variant="contained" style={{ backgroundColor: 'white', color: 'black' }} key={data.Invoice_ID} disableElevation onClick={() => {
                    if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                      var obj1 = {
                        sample_id: data.Invoice_ID,
                        age_gender: data.Age_gender,
                        test_title: "Nasal Swab/Nasal Secretions",
                        date: new Date().ddmmyyy(),
                        mpl_id: data.Invoice_ID,
                        name: data.NAME_F + " " + data.NAME_L,
                        patient_id: data.SYSTEM_ID,
                        time: formatAMPM(new Date()),
    
                      }
                      var url = "http://localhost:1000/GETBarcode"
                      fetch(url, {
                        method: "POST",
                        body: JSON.stringify(obj1),
                        headers: {
                          'Content-Type': "application/json"
                        }
                      }).then(() => {
                        alert("Print Request Sent")
                      })
                    } else {
                      alert("Only for COVID-19 Reports")
                    }
                  }} >
                    Barcode
              </Button>
                </center>
                ,
                patient_invoice: <center>
                  <Button variant="contained" style={{ backgroundColor: 'purple', color: 'white' }} key={data.Invoice_ID} disableElevation onClick={() => {
    
                    if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                      if (Focus) {
                        return
                      }
                      setFocus(true)
                      fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + data.InV_AuX_ID).then(res => res.json()).then((response) => {
                        svgString2Image(response[0].barcode, 800, 600, 'png', (barcode) => {
    
                          var flight_date = new Date(data.Flight_Date).ddmmyyy()
                          var print_date = new Date().ddmmyyy()
                          var invoice_date = new Date(data.ACCESS_DATE).ddmmyyy()
    
                          var obj1 = {
                            branch: data.B_TiTLE,
                            barcode: barcode,
                            name: data.NAME_F + " " + data.NAME_L,
                            age_gender: data.Age_gender,
                            password: data.Patient_PW,
                            patient_id: data.SYSTEM_ID,
                            print_time: formatAMPM(new Date()),
                            print_date: print_date,
                            invoice_time: msToTime(data.ACCESS_TiME),
                            invoice_date: invoice_date,
                            User_Name: data.User_Name,
                            invoice_id: data.Invoice_ID,
                            contact: data.Mobile_No,
                            Panel_Name: data.Panel_Name,
                            passport_no: data.Passport_No,
                            flight_date: flight_date,
                            flight_no: data.Flight_no,
                            flight_time: msToTime(data.Flight_Time),
                            toAddress: data.To_Address,
                            reports: [
                              {
                                sn: "1", test_title: " COVID-19 RNA BY PCR", reporting_date: data.Rep_TimeDate, fee: covidRate, disc: "0.00", net_value: covidRate,
                                flight_date: flight_date,
                                flight_no: data.Flight_no,
                                flight_time: msToTime(data.Flight_Time),
                                passport_no: data.Passport_No,
                                destination: data.Destination,
                                UrgentCase: data.UrgentCase,
                                branch: data.B_TiTLE,
                                User_Name: data.User_Name,
                                cnic: data.CNIC,
                                name: data.NAME_F + " " + data.NAME_L,
                                passport: data.Passport_No,
    
                                user_id: data.User_ID
                              }
                            ]
                          }
                          setTimeout(() => {
                            setSelected(obj1)
    
                          }, 500);
                          setTimeout(() => {
                            setFocus(false)
    
                          }, 5000);
                          setPatientInvoice(true)
                        })
                      })
                    } else {
                      alert("Only for COVID-19 Reports")
                    }
                  }}>
                    <  ReceiptIcon />
                  </Button>
                </center>
                ,
                lab_order: <center>
                  <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} key={data.Invoice_ID} disableElevation onClick={() => {
                    if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                      if (Focus) {
                        return
                      }
                      setFocus(true)
                      fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + data.InV_AuX_ID).then(res => res.json()).then((response) => {
                        svgString2Image(response[0].barcode, 800, 600, 'png', (barcode) => {
                          var flight_date = new Date(data.Flight_Date).ddmmyyy()
                          var print_date = new Date().ddmmyyy()
                          var invoice_date = new Date(data.ACCESS_DATE).ddmmyyy()
                          ///////////// Hours  Sample Diff
                          var Sample_Date = new Date(data.ACCESS_DATE)
                          var Sample_Time = FlightToTime(response[0].access_time)
                          Sample_Date.setHours(Sample_Time.split(":")[0], Sample_Time.split(":")[1])
    
                          //////////////
                          /////////////Hours Flight Time
                          var flightDate = new Date(response[0].flight_date)
                          var flight_time = FlightToTime(response[0].flight_time)
                          flightDate.setHours(flight_time.split(":")[0], flight_time.split(":")[1])
    
                          /////////////
    
                          const milliseconds = Math.abs(flightDate - Sample_Date);
                          const hours = Math.ceil(milliseconds / 36e5)
    
    
                          var obj1 = {
                            branch: data.B_TiTLE,
                            name: data.NAME_F + " " + data.NAME_L,
                            age_gender: data.Age_gender,
                            password: data.Patient_PW,
                            barcode: barcode,
                            Hours_Diff: hours,
                            Base64_ImagePassport: "data:image/png;base64," + response[0].Base64_ImagePassport,
                            Base64_PicturePatient: "data:image/png;base64," + response[0].Base64_PicturePatient,
                            Base64_ImageSampleTaking: "data:image/png;base64," + response[0].Base64_ImageSampleTaking,
                            patient_id: data.SYSTEM_ID,
                            print_time: formatAMPM(new Date()),
                            print_date: print_date,
                            invoice_time: msToTime(data.ACCESS_TiME),
                            invoice_date: invoice_date,
                            invoice_id: data.Invoice_ID,
                            contact: data.Mobile_No,
                            passport_no: data.Passport_No,
                            destination: data.Destination,
                            Airline: data.Airline,
                            Ticket_no: data.Ticket_no,
                            Panel_Name: data.Panel_Name,
                            User_Name: data.User_Name,
                            flight_date: flight_date,
                            flight_no: data.Flight_no,
                            flight_time: msToTime(data.Flight_Time),
                            toAddress: data.To_Address,
                            reports: [
                              {
                                sn: "1", test_title: " COVID-19 RNA BY PCR", reporting_date: data.Rep_TimeDate,
                                flight_date: flight_date,
                                flight_no: data.Flight_no,
                                flight_time: msToTime(data.Flight_Time),
                                passport_no: data.Passport_No,
                                User_Name: data.User_Name,
                                Airline: data.Airline,
                                Ticket_no: data.Ticket_no,
                                destination: data.Destination,
                                cnic: data.CNIC,
                                invoice_time: msToTime(data.ACCESS_TiME),
                                invoice_date: invoice_date,
                                name: data.NAME_F + " " + data.NAME_L,
                                passport: data.Passport_No,
                                user_id: data.User_ID
    
                              }
                            ]
                          }
    
                          setTimeout(() => {
                            setLabOrder(true)
    
                          }, 1000);
    
                          setTimeout(() => {
                            setFocus(false)
    
                          }, 5000);
                          setSelected(obj1)
                        })
                      })
    
                    } else {
                      setFocus(false)
                      alert("Only for COVID-19 Reports")
                    }
                  }}>
                    <  ReceiptIcon />
                  </Button>
                </center>
                ,
                invoice: <center>
                  <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} disableElevation onClick={() => {
                    if (data.T_TiTLE != "Covid-19 RNA by PCR") {
    
                      window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetInvoicePDF/' + data.InV_AuX_ID)
                    } else {
                      alert("Kindly view COVID-19 Invoice")
                    }
                  }}>
                    <  ReceiptIcon />
                  </Button>
                </center>
                ,
                download:
                  <center>
                    {
                      (data.DOnotShowUnpaidReport == true && data.T_TiTLE != "Covid-19 RNA by PCR")
                        ?
    
                        <Button onClick={() => { alert("Report can't be shown until Balance is Due") }}>
                          <HelpIcon />
                        </Button>
                        : (data.T_TiTLE == "Covid-19 RNA by PCR" && !isMobile && !data.Airline.toLowerCase().includes('qatar') && !data.Airline.toLowerCase().includes('turkish')) ?
                          <Button variant="contained" color="secondary" key={data.Invoice_ID} disabled={covidButton} onClick={() => {
                            if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                              if (data.Test_Status == 'Results Ready') {
    
                                fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + data.InV_AuX_ID).then(res => res.json()).then((response) => {
    
    
                                  if (response[0].result_date.includes("Not yet Signed")) {
                                    alert("Results not Ready Yet")
                                    return
                                  }
                                  setCovidButton(true)
                                  svgString2Image(response[0].QR_Code_Online, 300, 300, 'png', (QR_Code_Online) => {
                                    svgString2Image(response[0].barcode, 800, 600, 'png', (barcode) => {
                                      svgString2Image(response[0].QR_Code_Offline, 300, 300, 'png', (QR_Code_Offline) => {
                                        var test_name = response[0].test_name
                                        if (response[0].test_name == "") {
                                          test_name = "Covid-19 RNA by PCR"
    
                                        }
    
                                        var cnic = response[0].cnic + ""
                                        var validated_cnic = cnic.replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3")
    
                                        var covid_record = {
                                          barcode: barcode,
                                          invoice_id: response[0].invoice_id,
                                          QR_Code_Online: QR_Code_Online,
                                          QR_Code_Offline: QR_Code_Offline,
                                          name: response[0].name,
                                          passport: response[0].passport,
                                          ShouldSampleDateTimePrint: response[0].ShouldSampleDateTimePrint,
                                          title: test_name,
                                          age_gender: response[0].age_gender,
                                          specimen_date: response[0].specimen_date,
                                          specimen_time: response[0].specimen_Time,
                                          cnic: validated_cnic,
                                          list: [{
                                            title: test_name,
                                            reports: [{
                                              result: response[0].result,
                                              test_name: test_name,
                                              comments: "",
                                              ref_range: "",
                                              prev1: "--",
                                              prev2: "--"
    
                                            }],
                                            id: response[0].login_id,
                                            password: response[0].Password
                                          }],
                                          toAddress: response[0].toAddress,
                                          result_date: response[0].result_date,
                                          result_time: response[0].result_time,
                                          user_id: response[0].User_Name.toUpperCase()
                                        }
    
                                        setSelectedCovid(covid_record)
                                        setTimeout(() => {
                                          setCovidButton(false)
                                          setCovidTemplate(true)
    
                                        }, 800)
                                      })
                                    })
                                  })
                                })
                              } else {
                                alert("Results not Ready Yet")
                              }
    
    
                            } else {
                              alert("Only for COVID-19 Reports")
                            }
                          }}>
                            {covidButton ? "Loading......" : <  PictureAsPdfSharpIcon />}
                          </Button>
                          :
                          <Button variant="contained" color="secondary" disableElevation onClick={() => {
                            if (data.Test_Status == 'Results Ready') {
                              window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/' + data.InV_AuX_ID)
    
                            } else {
                              alert('Results not Ready!!')
                            }
                          }} >
                            <  PictureAsPdfSharpIcon />
                          </Button>
    
                    }
    
                  </center>
    
    
                ,
                official_covid_report:
                  <center>
                    {
                      data.T_TiTLE == "Covid-19 RNA by PCR" ?
                        <Button variant="contained" color="info" key={data.Invoice_ID} disabled={covidButton} onClick={() => {
                          if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                            if (data.Test_Status == 'Results Ready') {
                              setCovidButton(true)
                              fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + data.InV_AuX_ID).then(res => res.json()).then((response) => {
    
                                svgString2Image(response[0].QR_Code_Online, 300, 300, 'png', (QR_Code_Online) => {
                                  svgString2Image(response[0].barcode, 800, 600, 'png', (barcode) => {
                                    svgString2Image(response[0].QR_Code_Offline, 300, 300, 'png', (QR_Code_Offline) => {
                                      var test_name = response[0].test_name
                                      if (response[0].test_name == "") {
                                        test_name = "Covid-19 RNA by PCR"
    
                                      }
                                      var cnic = response[0].cnic + ""
                                      var validated_cnic = cnic.replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3")
    
                                      var covid_record = {
                                        barcode: barcode,
                                        invoice_id: response[0].invoice_id,
                                        QR_Code_Online: QR_Code_Online,
                                        QR_Code_Offline: QR_Code_Offline,
                                        name: response[0].name,
                                        ShouldSampleDateTimePrint: response[0].ShouldSampleDateTimePrint,
                                        passport: response[0].passport,
                                        title: test_name,
                                        age_gender: response[0].age_gender,
                                        specimen_date: response[0].specimen_date,
                                        specimen_time: response[0].specimen_Time,
                                        cnic: validated_cnic,
                                        list: [{
                                          title: test_name,
                                          reports: [{
                                            result: response[0].result,
                                            test_name: test_name,
                                            comments: "",
                                            ref_range: "",
                                            prev1: "--",
                                            prev2: "--"
    
                                          }],
                                          id: response[0].login_id,
                                          password: response[0].Password
                                        }],
                                        toAddress: response[0].toAddress,
                                        result_date: response[0].result_date,
                                        result_time: response[0].result_time,
                                        user_id: response[0].User_Name.toUpperCase()
                                      }
    
                                      setSelectedOfficialCovidReport(covid_record)
                                      setTimeout(() => {
                                        setOfficialCovidTemplate(true)
    
                                      }, 800)
                                    })
                                  })
                                })
                              })
                            } else {
                              alert("Results not Ready Yet")
                            }
    
    
                          } else {
                            alert("Only for COVID-19 Reports")
                          }
                        }}>
                          {covidButton ? "Loading......" : <  PictureAsPdfSharpIcon />}
                        </Button>
                        :
                        null
                    }
    
                  </center>
              }
              the_rows.push(obj)
    
            })
    
            setRows(the_rows)
            setOrg_Rows(the_rows)
            setLoading(false)
          })
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetAllPanelsList").then((res) => res.json())
          .then((result) => {
            setpanelList(result)
          })
        if (localStorage.getItem('org_branch_id') == '0') {
          fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
            setBranches(response)
            var obj = {}
            response.map((item) => {
              obj[item.BranchID] = item.BranchName
            })
            setSelectBranch(obj)
    
            setBranch(localStorage.getItem('branch_id'))
          })
        }
    
      })

  }, [])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes();
    var hh = this.getHours();

    return [
      (hh > 9 ? '' : '0') + hh,
      (mm > 9 ? '' : '0') + mm
    ].join(':');
  };

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  return (
    <>
      <Paper className={classes.root}>
        <div style={{ padding: '1em', textAlign: 'center' }}>
          {localStorage.getItem('org_branch_id') == '0' ? <h3 style={{ color: 'red' }}>Branch Selection will change the Booking, Invoicing and Reporting Branch</h3> : null}
        </div>
        <GridContainer style={{ padding: '2em' }}>
          <GridItem xs={6} sm={6} md={2} lg={2}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                label="From"
                format="dd/MM/yyyy"
                minDate={new Date().setMonth(new Date().getMonth() - 1)}
                maxDate={new Date()}
                value={from}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => setFrom(date)}
              />
            </MuiPickersUtilsProvider>
          </GridItem>
          {
            width < 600
              ?
              <GridItem xs={6} sm={6} md={0} lg={0}>

              </GridItem> : ""
          }

          <GridItem xs={6} sm={6} md={2} lg={2}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                label="To"
                format="dd/MM/yyyy"
                value={to}
                minDate={from}
                maxDate={new Date()}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => setTo(date)}
              />
            </MuiPickersUtilsProvider>

          </GridItem>
          {
            width < 600
              ?
              <GridItem xs={6} sm={6} md={0} lg={0}>

              </GridItem> : ""
          }
          <GridItem xs={6} sm={6} md={2} lg={2}>
            <FormControl variant="filled" className={classes.formControl} style={{ width: "-webkit-fill-available" }} >
              <InputLabel id="demo-simple-select-filled-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={state.status}
                onChange={handleChange}
                inputProps={{
                  name: 'status',
                  id: 'filled-age-native-simple',
                }}
              >
                <MenuItem aria-label="Select" value="All" >All</MenuItem>
                <MenuItem value={'Just Booked'}>Just Booked</MenuItem>
                <MenuItem value={'Only Phlebotomy Done'}>Only Phlebotomy Done</MenuItem>
                <MenuItem value={'Lying Pending Results'}>Lying Pending Results</MenuItem>
                <MenuItem value={'Results Done But not Signed'}>Results Done But not Signed</MenuItem>
                <MenuItem value={'Results Ready'}>Results Ready</MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          {
            localStorage.getItem('org_branch_id') == '0' ?
              <GridItem xs={12} sm={12} md={2} lg={2}  >
                <FormControl variant="filled" className={classes.formControl} style={{ width: "10em" }} >
                  <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={branch}
                    onChange={(e) => {
                      var result = window.confirm('Are you Sure ?. Your want to change Branch?')
                      if (result) {
                        setBranch(e.target.value)
                        localStorage.setItem('branch_id', e.target.value)
                        localStorage.setItem('branch_name', selectBranch[e.target.value])
                        var branchInfo=BranchContactInfo[e.target.value]
                        localStorage.setItem('branch_province',(branchInfo.Province+""))
                        window.location.reload()
                      }

                    }}
                  >
                    <MenuItem value={"0"} disabled>Select</MenuItem>
                    {
                      branches.map((item) => {
                        return <MenuItem value={item.BranchID} key={item.BranchID}>{item.BranchName.split('MPL, ')[1]}</MenuItem>

                      })
                    }
                  </Select>
                </FormControl>
              </GridItem>
              : null
          }
          <GridItem xs={12} sm={12} md={2} lg={2} style={{ margin: 'auto' }} >

            <Button variant="contained" color="primary" disableElevation disabled={loading}
              onClick={() => {
                fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
                  setBranches(response)
                  var obj = {}
                  var objBranchInfo={}
                  response.map((item) => {
                    obj[item.BranchID] = item.BranchName
                    objBranchInfo[item.BranchID]={
                      ...item
                    }
                  })
                  
                  if(objBranchInfo!={}){
                    var branchInfo=objBranchInfo[parseInt(localStorage.getItem('branch_id'))]
                    console.log(branchInfo)
                    localStorage.setItem('branch_province',(branchInfo.Province+""))
                    var rate=localStorage.getItem("branch_province")==undefined || localStorage.getItem("branch_province")==null  ? "3,500" : localStorage.getItem("branch_province").trim()=="" ? "3,500" : (localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="federal" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="kpk" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="ajk" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="gb" || localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="balochistan") ? "5,000" : localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="punjab" ? "3,000" : localStorage.getItem("branch_province").trim().toLocaleLowerCase()=="sindh" ? "4,500" : "3,500"
                    setCovidrate(rate)
                  }
                  
                })
                setPage(0)
                document.getElementById('search_data').value = ""
                var role = localStorage.getItem('role')
                var patient_id = localStorage.getItem('patient_id')
                setLoading(true)

                var branchID = localStorage.getItem('branch_id')
                var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID: branchID }
                var vSearchStr = JSON.stringify(SearchFormData);
                var user_id=patient_id+""
                var vRole=role
                if(localStorage.getItem("panel_code")){
                  user_id=localStorage.getItem("panel_code")
                  vRole="Panel"
                }
                
            
                var url = "https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetTestListwithStatus?vQuery=" + vSearchStr + "&vUID=" + user_id + "&vRole=" + vRole
            
                fetch(url).then((res) => res.json())
                  .then((result) => {
                    var the_rows = []
                    var name = localStorage.getItem('patient_name')
                    result.map((data) => {
                      var br_title = ""
                      if (data.B_TiTLE == "METROPOLE") {
                        br_title = "Islamabad"
                      } else {

                        br_title = data.B_TiTLE.split('MPL,')[1]


                      }
                      var obj = {
                        id: data.Invoice_ID,
                        name: data.NAME_F + " " + data.NAME_L,
                        title: data.T_TiTLE,
                        status: data.Test_Status,
                        cnic: data.CNIC,
                        passport: data.Passport_No,
                        mobile: data.Mobile_No,
                        reference_no: data.Reference_No,
                        code: data.T_CODE,
                        Panel_Code: data.Panel_Code,
                        Panel_Name: data.Panel_Name,
                        branch: br_title,
                        dateObject: new Date(data.ACCESS_DATE),
                        edit :<Button variant="contained" style={{backgroundColor:'red', color:'white'}} disableElevation onClick={()=>{
                          setinvoiceEdit(data.Invoice_ID)
                          setOpenEdit(true)
                        }}>
                             <EditIcon />
                         </Button>,
                        refresh: <center>
                          <Button variant="contained" style={{ backgroundColor: 'skyblue', color: 'white' }} disableElevation onClick={() => {
                            if (data.Test_Status == 'Results Ready') {

                              window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF_BlobRefresh/' + data.InV_AuX_ID)
                            } else {
                              alert('Results not Ready!!')
                            }
                          }}>
                            <  ReceiptIcon />
                          </Button>
                        </center>
                        ,
                        barcode: <center>
                          <Button variant="contained" style={{ backgroundColor: 'white', color: 'black' }} key={data.Invoice_ID} disableElevation onClick={() => {
                            if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                              var obj1 = {
                                sample_id: data.Invoice_ID,
                                age_gender: data.Age_gender,
                                test_title: "Nasal Swab/Nasal Secretions",
                                date: new Date().ddmmyyy(),
                                mpl_id: data.Invoice_ID,
                                name: data.NAME_F + " " + data.NAME_L,
                                patient_id: data.SYSTEM_ID,
                                time: formatAMPM(new Date()),

                              }
                              var url = "http://localhost:1000/GETBarcode"
                              fetch(url, {
                                method: "POST",
                                body: JSON.stringify(obj1),
                                headers: {
                                  'Content-Type': "application/json"
                                }
                              }).then(() => {
                                alert("Print Request Sent")
                              })
                            } else {
                              alert("Only for COVID-19 Reports")
                            }
                          }} >
                            Barcode
       </Button>
                        </center>
                        ,
                        patient_invoice: <center>
                          <Button variant="contained" style={{ backgroundColor: 'purple', color: 'white' }} key={data.Invoice_ID} disableElevation onClick={() => {
                            if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                              if (Focus) {
                                return
                              }
                              setFocus(true)
                              fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + data.InV_AuX_ID).then(res => res.json()).then((response) => {
                                svgString2Image(response[0].barcode, 800, 600, 'png', (barcode) => {

                                  var flight_date = new Date(data.Flight_Date).ddmmyyy()
                                  var print_date = new Date().ddmmyyy()
                                  var invoice_date = new Date(data.ACCESS_DATE).ddmmyyy()

                                  var obj1 = {
                                    branch: data.B_TiTLE,
                                    barcode: barcode,
                                    name: data.NAME_F + " " + data.NAME_L,
                                    age_gender: data.Age_gender,
                                    password: data.Patient_PW,
                                    patient_id: data.SYSTEM_ID,
                                    print_time: formatAMPM(new Date()),
                                    print_date: print_date,
                                    invoice_time: msToTime(data.ACCESS_TiME),
                                    invoice_date: invoice_date,
                                    User_Name: data.User_Name,
                                    invoice_id: data.Invoice_ID,
                                    contact: data.Mobile_No,
                                    Panel_Name: data.Panel_Name,
                                    passport_no: data.Passport_No,
                                    flight_date: flight_date,
                                    flight_no: data.Flight_no,
                                    flight_time: msToTime(data.Flight_Time),
                                    toAddress: data.To_Address,
                                    reports: [
                                      {
                                        sn: "1", test_title: " COVID-19 RNA BY PCR", reporting_date: data.Rep_TimeDate, fee: covidRate, disc: "0.00", net_value: covidRate,
                                        flight_date: flight_date,
                                        flight_no: data.Flight_no,
                                        flight_time: msToTime(data.Flight_Time),
                                        passport_no: data.Passport_No,
                                        destination: data.Destination,
                                        UrgentCase: data.UrgentCase,
                                        branch: data.B_TiTLE,
                                        User_Name: data.User_Name,
                                        cnic: data.CNIC,
                                        name: data.NAME_F + " " + data.NAME_L,
                                        passport: data.Passport_No,

                                        user_id: data.User_ID
                                      }
                                    ]
                                  }
                                  setTimeout(() => {
                                    setSelected(obj1)

                                  }, 500);
                                  setTimeout(() => {

                                    setFocus(false)

                                  }, 5000);
                                  setPatientInvoice(true)
                                })
                              })
                            } else {
                              setFocus(false)
                              alert("Only for COVID-19 Reports")
                            }
                          }}>
                            <  ReceiptIcon />
                          </Button>
                        </center>
                        ,
                        lab_order: <center>
                          <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} key={data.Invoice_ID} disableElevation onClick={() => {
                            if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                              if (Focus) {
                                return
                              }
                              setFocus(true)
                              fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + data.InV_AuX_ID).then(res => res.json()).then((response) => {
                                svgString2Image(response[0].barcode, 800, 600, 'png', (barcode) => {

                                  var flight_date = new Date(data.Flight_Date).ddmmyyy()
                                  var print_date = new Date().ddmmyyy()
                                  var invoice_date = new Date(data.ACCESS_DATE).ddmmyyy()
                                  ///////////// Hours  Sample Diff

                                  var Sample_Date = new Date(data.ACCESS_DATE)
                                  var Sample_Time = FlightToTime(response[0].access_time)
                                  Sample_Date.setHours(Sample_Time.split(":")[0], Sample_Time.split(":")[1])

                                  //////////////
                                  /////////////Hours Flight Time
                                  var flightDate = new Date(response[0].flight_date)
                                  var flight_time = FlightToTime(response[0].flight_time)
                                  flightDate.setHours(flight_time.split(":")[0], flight_time.split(":")[1])

                                  /////////////

                                  const milliseconds = Math.abs(flightDate - Sample_Date);
                                  const hours = Math.ceil(milliseconds / 36e5)


                                  var obj1 = {
                                    branch: data.B_TiTLE,
                                    name: data.NAME_F + " " + data.NAME_L,
                                    age_gender: data.Age_gender,
                                    password: data.Patient_PW,
                                    barcode: barcode,
                                    Hours_Diff: hours,
                                    Base64_ImagePassport: "data:image/png;base64," + response[0].Base64_ImagePassport,
                                    Base64_PicturePatient: "data:image/png;base64," + response[0].Base64_PicturePatient,
                                    Base64_ImageSampleTaking: "data:image/png;base64," + response[0].Base64_ImageSampleTaking,
                                    patient_id: data.SYSTEM_ID,
                                    print_time: formatAMPM(new Date()),
                                    print_date: print_date,
                                    invoice_time: msToTime(data.ACCESS_TiME),
                                    invoice_date: invoice_date,
                                    invoice_id: data.Invoice_ID,
                                    contact: data.Mobile_No,
                                    passport_no: data.Passport_No,
                                    destination: data.Destination,
                                    Airline: data.Airline,
                                    Ticket_no: data.Ticket_no,
                                    Panel_Name: data.Panel_Name,
                                    User_Name: data.User_Name,
                                    flight_date: flight_date,
                                    flight_no: data.Flight_no,
                                    flight_time: msToTime(data.Flight_Time),
                                    toAddress: data.To_Address,
                                    reports: [
                                      {
                                        sn: "1", test_title: " COVID-19 RNA BY PCR", reporting_date: data.Rep_TimeDate,
                                        flight_date: flight_date,
                                        flight_no: data.Flight_no,
                                        flight_time: msToTime(data.Flight_Time),
                                        passport_no: data.Passport_No,
                                        User_Name: data.User_Name,
                                        Airline: data.Airline,
                                        Ticket_no: data.Ticket_no,
                                        destination: data.Destination,
                                        cnic: data.CNIC,
                                        invoice_time: msToTime(data.ACCESS_TiME),
                                        invoice_date: invoice_date,
                                        name: data.NAME_F + " " + data.NAME_L,
                                        passport: data.Passport_No,
                                        user_id: data.User_ID

                                      }
                                    ]
                                  }
                                  setTimeout(() => {
                                    setLabOrder(true)

                                  }, 1000);
                                  setTimeout(() => {

                                    setFocus(false)

                                  }, 5000);
                                  setSelected(obj1)
                                })

                              })

                            } else {
                              alert("Only for COVID-19 Reports")
                            }

                          }}>
                            <  ReceiptIcon />
                          </Button>
                        </center>
                        ,
                        invoice: <center>
                          <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} disableElevation onClick={() => {
                            if (data.T_TiTLE != "Covid-19 RNA by PCR") {
                              window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetInvoicePDF/' + data.InV_AuX_ID)
                            } else {
                              alert("Kindly view COVID-19 Invoice")
                            }
                          }}>
                            <  ReceiptIcon />
                          </Button>
                        </center>,

                        download:


                          <center>
                            {
                              (data.DOnotShowUnpaidReport == true && data.T_TiTLE != "Covid-19 RNA by PCR")
                                ?

                                <Button onClick={() => { alert("Report can't be shown until Balance is Due") }}>
                                  <HelpIcon />
                                </Button>
                                : (data.T_TiTLE == "Covid-19 RNA by PCR" && !isMobile && !data.Airline.toLowerCase().includes('qatar') && !data.Airline.toLowerCase().includes('turkish')) ?
                                  <Button variant="contained" color="secondary" key={data.Invoice_ID} disabled={covidButton} onClick={() => {
                                    if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                                      if (data.Test_Status == 'Results Ready') {

                                        fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + data.InV_AuX_ID).then(res => res.json()).then((response) => {
                                          if (response[0].result_date.includes("Not yet Signed")) {
                                            alert("Results not Ready Yet")
                                            return
                                          }
                                          setCovidButton(true)
                                          svgString2Image(response[0].QR_Code_Online, 300, 300, 'png', (QR_Code_Online) => {
                                            svgString2Image(response[0].barcode, 800, 600, 'png', (barcode) => {
                                              svgString2Image(response[0].QR_Code_Offline, 300, 300, 'png', (QR_Code_Offline) => {
                                                var test_name = response[0].test_name
                                                if (response[0].test_name == "") {
                                                  test_name = "Covid-19 RNA by PCR"

                                                }
                                                var cnic = response[0].cnic + ""
                                                var validated_cnic = cnic.replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3")

                                                var covid_record = {
                                                  barcode: barcode,
                                                  invoice_id: response[0].invoice_id,
                                                  QR_Code_Online: QR_Code_Online,
                                                  QR_Code_Offline: QR_Code_Offline,
                                                  name: response[0].name,
                                                  passport: response[0].passport,
                                                  ShouldSampleDateTimePrint: response[0].ShouldSampleDateTimePrint,
                                                  title: test_name,
                                                  age_gender: response[0].age_gender,
                                                  specimen_date: response[0].specimen_date,
                                                  specimen_time: response[0].specimen_Time,
                                                  cnic: validated_cnic,
                                                  list: [{
                                                    title: test_name,
                                                    reports: [{
                                                      result: response[0].result,
                                                      test_name: test_name,
                                                      comments: "",
                                                      ref_range: "",
                                                      prev1: "--",
                                                      prev2: "--"

                                                    }],
                                                    id: response[0].login_id,
                                                    password: response[0].Password
                                                  }],
                                                  toAddress: response[0].toAddress,
                                                  result_date: response[0].result_date,
                                                  result_time: response[0].result_time,
                                                  user_id: response[0].User_Name.toUpperCase()
                                                }

                                                setSelectedCovid(covid_record)
                                                setTimeout(() => {
                                                  setCovidButton(false)
                                                  setCovidTemplate(true)

                                                }, 800)
                                              })
                                            })
                                          })
                                        })
                                      } else {
                                        alert("Results not Ready Yet")
                                      }


                                    } else {
                                      alert("Only for COVID-19 Reports")
                                    }
                                  }}>
                                    {covidButton ? "Loading......" : <  PictureAsPdfSharpIcon />}
                                  </Button>
                                  :
                                  <Button variant="contained" color="secondary" disableElevation onClick={() => {
                                    if (data.Test_Status == 'Results Ready') {
                                      window.open('https://reports.mpl-labs.pk:8443/4DACTION/GetReportPDF/' + data.InV_AuX_ID)

                                    } else {
                                      alert('Results not Ready!!')
                                    }
                                  }} >
                                    <  PictureAsPdfSharpIcon />
                                  </Button>

                            }

                          </center>
                        ,
                        official_covid_report:
                          <center>
                            {
                              data.T_TiTLE == "Covid-19 RNA by PCR" ?
                                <Button variant="contained" color="info" key={data.Invoice_ID} disabled={covidButton} onClick={() => {
                                  if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                                    if (data.Test_Status == 'Results Ready') {
                                      setCovidButton(true)
                                      fetch("https://reports.mpl-labs.pk:8443/4DACTION/GetReportCovidJSON/" + data.InV_AuX_ID).then(res => res.json()).then((response) => {

                                        svgString2Image(response[0].QR_Code_Online, 300, 300, 'png', (QR_Code_Online) => {
                                          svgString2Image(response[0].barcode, 800, 600, 'png', (barcode) => {
                                            svgString2Image(response[0].QR_Code_Offline, 300, 300, 'png', (QR_Code_Offline) => {
                                              var test_name = response[0].test_name
                                              if (response[0].test_name == "") {
                                                test_name = "Covid-19 RNA by PCR"

                                              }
                                              var cnic = response[0].cnic + ""
                                              var validated_cnic = cnic.replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3")

                                              var covid_record = {
                                                barcode: barcode,
                                                invoice_id: response[0].invoice_id,

                                                name: response[0].name,
                                                ShouldSampleDateTimePrint: response[0].ShouldSampleDateTimePrint,
                                                passport: response[0].passport,
                                                title: test_name,
                                                age_gender: response[0].age_gender,
                                                specimen_date: response[0].specimen_date,
                                                specimen_time: response[0].specimen_Time,
                                                QR_Code_Online: QR_Code_Online,
                                                QR_Code_Offline: QR_Code_Offline,
                                                cnic: validated_cnic,
                                                list: [{
                                                  title: test_name,
                                                  reports: [{
                                                    result: response[0].result,
                                                    test_name: test_name,
                                                    comments: "",
                                                    ref_range: "",
                                                    prev1: "--",
                                                    prev2: "--"

                                                  }],
                                                  id: response[0].login_id,
                                                  password: response[0].Password
                                                }],
                                                toAddress: response[0].toAddress,
                                                result_date: response[0].result_date,
                                                result_time: response[0].result_time,
                                                user_id: response[0].User_Name.toUpperCase()
                                              }

                                              setSelectedOfficialCovidReport(covid_record)
                                              setTimeout(() => {
                                                setOfficialCovidTemplate(true)

                                              }, 800)
                                            })
                                          })
                                        })

                                      })
                                    } else {
                                      alert("Results not Ready Yet")
                                    }


                                  } else {
                                    alert("Only for COVID-19 Reports")
                                  }
                                }}>
                                  {covidButton ? "Loading......" : <  PictureAsPdfSharpIcon />}
                                </Button>
                                :
                                null
                            }

                          </center>
                      }
                      if (state.status == "All") {
                        the_rows.push(obj)

                      } else {
                        if (state.status.trim() == data.Test_Status.trim()) {
                          the_rows.push(obj)
                        }
                      }


                    })

                    setRows(the_rows)
                    setOrg_Rows(the_rows)
                    setLoading(false)
                    setPage(0)
                  })

              }}
            >
              Seach<SearchIcon />
            </Button>

          </GridItem>
        </GridContainer>
        <div align="right">

          <TextField id="standard-basic" id="search_data" label="Search by Invoice ID, Passport No , Reference No , CNIC , Patient Name , Mobile No , Test Name and Test Code"
            style={{ maxWidth: '50em' }}
            fullWidth={true}

            onChange={async (e) => {
              var lab_tests = []
              var text = e.target.value
              var data = await org_rows.map((item) => {
                if (item.title == "Covid-19 RNA by PCR") {
                  if (item.cnic.includes(text)) {
                    lab_tests.push(item)
                    return
                  }
                  if (item.passport.toLowerCase().includes(text.toLowerCase())) {
                    lab_tests.push(item)
                    return
                  }
                  if (item.reference_no.toLowerCase().includes(text.toLowerCase())) {
                    lab_tests.push(item)
                    return
                  }
                }
                if (item.mobile.includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                if (item.title.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                if (item.name.trim().toLowerCase().includes(text.trim().toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.code.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }
                if (item.id.toLowerCase().includes(text.toLowerCase())) {
                  lab_tests.push(item)
                  return

                }

                return item

              })
              Promise.all(data).then((item) => {

                setRows(lab_tests)

              })

            }}
          />

        </div>
        {loading ?
          <center>
            <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
          </center>
          :
          (localStorage.getItem('branch_id')!=undefined && localStorage.getItem('branch_id')!="0")
          ?
          <>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    {/* {
                      patientEdit
                      ?
                      <TableCell
                        key={'edit'}
                        align={'center'}
                      >
                       Edit Patient
                      </TableCell>
                      :
                      null
                    } */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code + Math.random()} >
                        {columns.map((column) => {
                          const value = row[column.id];
                          var status_color = value == 'Just Booked' ? 'brown' : value == 'Only Phlebotomy Done' ? 'red' :
                            value == 'Lying Pending Results' ? 'purple' : value == 'Results Done But not Signed' ? 'blue' : value == 'Results Ready' ? 'green' : ''

                          return (
                            <>
                              {status_color.trim() != '' ?
                                <TableCell key={column.id} align={column.align} style={{ backgroundColor: status_color, color: 'white' }} >
                                  <i><b>{value}</b></i>
                                </TableCell>
                                : <TableCell key={column.id} align={column.align} >
                                  {value}
                                </TableCell>}
                               
                            </>
                          );
                        })}
                         
                         {/* {
                                  patientEdit
                                  ?
                                  <TableCell
                                  key="edit"
                                  align="center"
                                  >
                                    {row['edit']}
                                  </TableCell>
                                  :
                                  null
                                } */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
          :
          null
        }


      </Paper>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={patient_invoice}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setPatientInvoice(false)
          setSelected({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Passenger Invoice</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selected.name != undefined ?
              <center> <Fragment key={"sqq"}>
                <PDFViewer width="1000" height="1000" className="app" >
                  <Invoice invoice={selected} />
                </PDFViewer>
              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setPatientInvoice(false)
              setSelected({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={laborder}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setLabOrder(false)
          setSelected({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Lab Order</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            laborder && selected.name != undefined ?
              <center> <Fragment key={"sqq"} id>
                <PDFViewer width="1000" height="1000" className="app" >
                  <LabOrder invoice={selected} />
                </PDFViewer>
              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setLabOrder(false)
              setSelected({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={OpenCovidTemplate}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setCovidTemplate(false)
          setSelectedCovid({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Lab Report</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selectedCovid.name != undefined ?
              <center> <Fragment key={"sqwewq"} >
                <GridContainer>
                  <GridItem md={2}>

                    <PDFDownloadLink
                      style={{
                        float: 'left'
                      }}
                      document={<CovidTemplate invoice={selectedCovid} />} fileName={selectedCovid.invoice_id + ".pdf"}>
                      {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
                        <Button variant="contained" color="primary">Download Report</Button>)}
                    </PDFDownloadLink>
                    <br />
                    <br />
                    <Button
                      style={{
                        float: 'left'
                      }}
                      variant="contained" color={'secondary'} onClick={() => {
                        setCovidTemplate(false)
                        var temp = selectedCovid
                        localStorage.setItem('covid_report', JSON.stringify(temp))
                        setSelectedCovid({
                          reports: []
                        })
                        //console.log(temp)
                        setTimeout((temp) => {
                          console.log(temp)
                          setSelectedCovid(JSON.parse(localStorage.getItem('covid_report')))
                          setTimeout(() => {
                            setCovidTemplate(true)
                          }, 500);

                        }, 400);
                      }}>
                      Refresh Report
       <RefreshIcon /></Button>

                  </GridItem>

                  <GridItem md={10}>
                    <PDFViewer width="1000" height="1000" className="app" >
                      <CovidTemplate invoice={selectedCovid} />
                    </PDFViewer>
                  </GridItem>
                </GridContainer>

              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setCovidTemplate(false)
              setSelectedCovid({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={OpenOfficialCovidTemplate}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setOfficialCovidTemplate(false)
          setSelectedOfficialCovidReport({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Lab Report</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selectedOfficialCovidReport.name != undefined ?
              <center> <Fragment key={"sqwewq"} >
                <GridContainer>
                  <GridItem md={2}>

                    <PDFDownloadLink
                      style={{
                        float: 'left'
                      }}
                      document={<OfficialCovidTemplate invoice={selectedOfficialCovidReport} />} fileName={selectedOfficialCovidReport.invoice_id + ".pdf"}>
                      {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
                        <Button variant="contained" color="primary">Download Report</Button>)}
                    </PDFDownloadLink>
                    <br />
                    <br />
                    <Button
                      style={{
                        float: 'left'
                      }}
                      variant="contained" color={'secondary'} onClick={() => {
                        setOfficialCovidTemplate(false)
                        var temp = selectedOfficialCovidReport
                        localStorage.setItem('official_covid_report', JSON.stringify(temp))
                        setSelectedOfficialCovidReport({
                          reports: []
                        })
                        //console.log(temp)
                        setTimeout((temp) => {
                          console.log(temp)
                          setSelectedOfficialCovidReport(JSON.parse(localStorage.getItem('official_covid_report')))
                          setTimeout(() => {
                            setOfficialCovidTemplate(true)
                          }, 500);

                        }, 400);
                      }}>
                      Refresh Report
       <RefreshIcon /></Button>

                  </GridItem>

                  <GridItem md={10}>
                    <PDFViewer width="1000" height="1000" className="app" >
                      <OfficialCovidTemplate invoice={selectedOfficialCovidReport} />
                    </PDFViewer>
                  </GridItem>
                </GridContainer>

              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setOfficialCovidTemplate(false)
              setSelectedOfficialCovidReport({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}

                  maxWidth={'xl'}
                  fullWidth={true}
                  open={OpenEdit}
                  TransitionComponent={Transition}
                  keepMounted
                  
                  onClose={() => {
                      setOpenEdit(false)
                 
                  }}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                   
                    <h4 className={classes.modalTitle}><b>Edit Patient</b></h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    
                    {OpenEdit ?
                     <center> 
                     <Fragment key={"sqq"}>
                     {/* <EditPatientByInvoice invoice_id={invoiceEdit}/> */}
              </Fragment>
              </center>: null}
                 
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => {
                          setOpenEdit(false)
                       
                      }}
                      color="danger"
                      simple
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>

    </>
  );
}
