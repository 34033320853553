import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({

  titleContainer: {
    flexDirection: 'column',
    marginTop: 24,
  },
  reportTitle: {
    color: 'black',
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'left',
  }
});


const InvoiceTitle = ({ item }) => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>{item.branch}</Text>
    <Text style={styles.reportTitle}>{"Printed on " + item.print_date + " at " + item.print_time}</Text>
  </View>
);

export default InvoiceTitle